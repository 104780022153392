import { Navigate } from 'react-router-dom';

import Login from "../Screens/Login/Login";
import Signup from '../Screens/Signup/Signup';

import Profile from "../Screens/Profile/Profile";
import ProfileBorrower from '../Screens/Profile/ProfileBorrower/ProfileBorrower';
import ProfileInvestor from '../Screens/Profile/ProfileInvestor/ProfileInvestor';

import Settings from '../Screens/Settings/Settings';
import SettingsProfile from '../Screens/Settings/SettingsProfile';
import SecuritySettings from '../Screens/Settings/SecuritySettings';
import RequisitesSettings from '../Screens/Settings/RequisitesSettings';

import DashboardLayout from "../Layouts/DashboardLayout/DashboardLayout";
import DashboardMain from '../Screens/DashBoardMain/DashboardMain';
import Error from '../Screens/Error/Error';
import Error404 from '../Screens/Error/Error404';
import MainLayout from '../Layouts/MainLayout/MainLayout';

import Project from '../Screens/Project/Project';
import NewProject from '../Screens/Project/NewProject';
import CurrentProject from '../Screens/Project/CurrentProject';
import ProjectView from '../Screens/Project/ProjectView';
import EditProject from '../Screens/Project/Editproject';
import ProjectLoan from '../Screens/Project/ProjectLoan';
import ProjectInvestment from '../Screens/Project/ProjectInvestment';

import Accounts from '../Screens/Accounts/Accounts';
import NewAccount from '../Screens/NewAccount/NewAccount';
import AccountSettings from '../Screens/AccountSettings/AccountSettings';
import SettingsTopup from '../Screens/AccountSettings/SettingsTopup/SettingsTopup';
import SettingsTransfer from '../Screens/AccountSettings/SettingsTransfer/SettingsTransfer';
import SettingsParams from '../Screens/AccountSettings/SettingsParams/SettingsParams';

const routes = (isLoggedIn, user) => [
    {
        element: isLoggedIn ? <DashboardLayout isLoggedIn={isLoggedIn}/> : <Navigate to="/login" />,
        children: [
            {path: 'main', element: <DashboardMain />, 
            children: [
                { path: 'user', element: <Profile />, 
                children: [
                    { path: 'borrower', element: <ProfileBorrower/> },
                    { path: 'investor', element: <ProfileInvestor/> }
                ] },
                {path: 'accounts', element: <Accounts/>},
            ]},
            {path: 'settings', element: <Settings />,
                children: [
                    { path: 'profile', element: <SettingsProfile /> },
                    { path: 'security', element: <SecuritySettings /> },
                    { path: 'requisites', element: <RequisitesSettings /> },
                ],
            },
            {path: 'new-account', element: <NewAccount/>},
            {path: 'account-settings/:accId', element: <AccountSettings/>, 
                children: [
                    { path: 'topup', element: <SettingsTopup /> },
                    { path: 'transfer', element: <SettingsTransfer /> },
                    { path: 'params', element: <SettingsParams /> },
                ]
            },
            { path: 'project', element: <Project />,
                children : [
                    { path: 'loan', element: <ProjectLoan/>,
                        children: [
                            { path: 'new', element: <NewProject /> },
                            { path: ':id', element: <CurrentProject  />,
                                children : [
                                    {path: 'info', element: <ProjectView  />},
                                    {path: 'edit', element: <EditProject  />}
                                ]
                            },
                        ]
                    },
                    { path: 'investment', element: <ProjectInvestment/>}
                ]
                
            }
        ],
    },
    {
        element: !isLoggedIn ? <MainLayout isLoggedIn={isLoggedIn}/> 
                             : <Navigate to={user.profile.businessRole ? `main/user/${user.profile.businessRole}` : "/settings"} />,
        
        children: [
            { path: 'login', element: <Login /> },
            {path: 'signup', element: <Signup />},
            { path: '/', element: <Navigate to="/login" /> },
        ],
    },
    {path: 'error404', element: <Error404/>},
    {path: 'error', element: <Error/>},
    {
        path: '*',
        element: <Error404/>
    }
];

export default routes;