import { makeAutoObservable, action, observable, computed } from "mobx";

class AccountsStore {

    accounts = null;

    constructor() {
        makeAutoObservable(this, {
            accounts: observable,
            getAccounts: computed,
            setAccounts: action,
        });
    }

    get getAccounts() {
        return this.accounts
    }

    setAccounts(accounts) {
        this.accounts = accounts
        console.log(this.accounts)
    }
    
}

export const accountsStore = new AccountsStore();