import { useState } from 'react';
import { useRoutes } from 'react-router-dom';

import Header from './ComponentsUI/Header/Header';
import AuthService from './services/AuthService';

import routes from './Config/RoutesConfig';



function App() {

    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"))

    const routing = useRoutes(routes(token, user));
    const [menuState, setMenuState] = useState(false);
    const {logout} = AuthService();

    const handleLogout = () => {
        logout('/auth/refresh-token')
    };

    return (
        <>  
            <div className="container">
            <Header 
                menuState={menuState} 
                setMenuState={setMenuState} 
                handleLogout={handleLogout} 
            />
            {routing}
            
        </div>
        </>
    );
}

export default App;
