import styles from './Footer.module.scss';
import vk from '../../assets/images/vk.png';
import whatsapp from '../../assets/images/whatsapp.png';
import telegram from '../../assets/images/telegram.png';
const Footer = () => {
    return (
        <>
            <footer className={styles.footer}>
                <div className={styles.footer__top}>
                    <div className={`wrapper ${styles.footer__wrapper}`}>
                    <div className={styles.column}>
                        <p className={styles.column__text}>
                            Инвестирование через Объединённую платформу ставит вас впереди инвесторов, 
                            которые не используют нашу инновационную стратегию.
                        </p>
                        <div className={styles.footer__links}>
                            <a href="#" className={styles.footer__link}><img src={vk} alt="VK"/></a>
                            <a href="#" className={styles.footer__link}><img src={whatsapp} alt="WhatsApp"/></a>
                            <a href="#" className={styles.footer__link}><img src={telegram} alt="Telegram"/></a>
                        </div>
                    </div>
                    <nav className={styles.column}>
                        <a href="#" className={styles.nav__link}>Новые проекты</a>
                        <a href="#" className={styles.nav__link}>Инвестиции</a>
                        <a href="#" className={styles.nav__link}>Список</a>
                        <a href="#" className={styles.nav__link}>Поддержка</a>
                    </nav>
                    <div className={styles.column}>
                        <p className={styles.footer__contact}>ОИП</p>
                        <a href="#" className={styles.footer__contact}>oip@inbox.com</a>
                        <a href="#" className={styles.footer__contact}>@oiptech</a>
                    </div>
                    </div>
                </div>

                <div className={styles.footer__bottom}>
                    <div className={`wrapper ${styles.footer__wrapper}`}>
                    <div className={styles.footer__policy}>
                        <p>© 2022 OIP, сертификация.</p>
                        <a href="" className={styles.footer__rules}>Правила сервиса</a>
                        <a href="" className={styles.footer__rules}>Политика конфиденциальности</a>
                    </div>

                    {/* <div className={styles.footer__langs}>
                        <p className={styles.footer__lang + ' ' + styles.active}>Русский</p>
                        <p className={styles.footer__lang}>English</p>
                    </div> */}
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;