
import {useState, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';


export const useDataApi = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [process, setProcess] = useState('waiting');
    const navigate = useNavigate();

    const request =  async (callback) => {
        setLoading(true);
        setProcess('loading');

        try  {
            const response = await callback;

            console.log(response)
            if (response.data.status !== 'ok') {
                console.log(response.data.code)
                setError(response.data.code)
            }

            setLoading(false);
            return response;
            
        }  catch(e) {
            setLoading(false);
            setError(e.message);
            setProcess('error');
            navigate('/error')
        }   

    };

    const clearError = useCallback(() => {
        setError(null);
        setProcess('loading');
    }, []);

    return {loading, request, error, setError, clearError, process, setProcess};
}