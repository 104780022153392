import { Field, ErrorMessage } from 'formik';
import TextError from '../TextError/TextError';
import styles from './Range.module.scss';

const Range = (props) => {
    const { name, initialValues, min, max, step } = props;
    const gradations = [1,2,3,4,5,6,7,8,9,10,11,12];
    return (
        <>
           <Field name={name}>
             {({
               field: {value, ...field},
               form: {setFieldValue, initialValues},
               meta,
             }) => (
                <>
                    <div className={styles.container}>
                        <input
                            type="range" 
                            min={min}
                            max={max}
                            step={step}
                            name={name}
                            id={name}
                            className={styles.range}
                            style={
                                {backgroundSize: (value - 1) * 100 / 11 + '% 100%'}
                            }
                            value={value ? value : initialValues[name]}
                            onChange={e => {
                                let target = e.target
                                if (e.target.type !== 'range') {
                                    target = document.getElementById(name)
                                } 
                                const min = target.min
                                const max = target.max
                                const val = parseInt(target.value)
                                setFieldValue(name, val)
                                target.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%';
                                console.log(setFieldValue(name, val))
                            }}
                            {...field} 
                        />

                        {meta.touched && meta.error && (
                            <ErrorMessage component={TextError} name={name} text={meta.error}/>
                        )}
                    </div>
                    <div className={styles.gradation__wrapper}>
                        {gradations.map((item, i) => (
                                <div 
                                className={styles.gradation} 
                                onClick={() => setFieldValue(name, item)}
                                key={i}>{item}</div>
                        ))}
                    </div>
                </>

             )}
           </Field>
        </>
    )
}

export default Range;