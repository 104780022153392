import { useState, useEffect } from 'react';
import styles from './Pagination.module.scss';
import PaginationList from '../PaginationList/PaginationList';
import { useMatchMedia } from '../../utils/useMatchMedia';
import { transactionsStore } from '../../stores/TransactionsStore';
import AuthService from '../../services/AuthService';
import DataService from '../../services/DataService';

const Pagination = () => {
    const {isNarrowScreen} = useMatchMedia() 
    const [searchOpen, setSearchOpen] = useState(false);

    const toggleSearch = () => {
        setSearchOpen(() => isNarrowScreen ? !searchOpen : null);
    }

    const { getData, process, setProcess } = AuthService();
    const { _transformTransactions} = DataService();

    useEffect(() => {
        setProcess('loading')
        getTransactions()
    }, []);

    const getTransactions = async () => {
        const response = getData('payments/transactions')
                .then(response => {
                    console.log(response)
                    if (response.data.transactions.length) {
                        onTransactionsLoaded(response.data.transactions)
                    } else {
                        transactionsStore.setTransactions([])
                    }
                })
                .then(() => setProcess('confirmed')) 
    }

    const onTransactionsLoaded = (data) => {
        let transactions = data.map(_transformTransactions)
        console.log(transactions)
        transactionsStore.setTransactions(transactions)
        console.log(transactionsStore.transactions)
        // localStorage.setItem("transactions", JSON.stringify(transactions))
        window.scrollTo(0,0)
    }

    
    const searchClassName = searchOpen ? `${styles.header__search} ${styles.search__open}` : `${styles.header__search}`;

    return (
        <>
            <div className={styles.table}>
                <div className={styles.table__top}>
                    <div className={styles.table__left}>
                        <div className={styles.header__center}>
                            <div className={searchClassName}>
                                <button className={styles.search__btn} onClick={toggleSearch}>
                                    <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512" fill="#767783">
                                        <path d="M23.707,22.293l-5.969-5.969a10.016,10.016,0,1,0-1.414,1.414l5.969,5.969a1,1,0,0,0,1.414-1.414ZM10,18a8,8,0,1,1,8-8A8.009,8.009,0,0,1,10,18Z"/>
                                    </svg>
                                </button>
                                <input className={styles.search__inp} type="text" placeholder="Искать операцию..."/>
                            </div>
                        </div>

                    </div>
                    <div className={styles.table__right}>
                        <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M21.0602 0.342041H0.939769C0.690527 0.342041 0.451492 0.433534 0.275251 0.596395C0.0990104 0.759255 0 0.980142 0 1.21046C0 1.44078 0.0990104 1.66167 0.275251 1.82453C0.451492 1.98739 0.690527 2.07888 0.939769 2.07888H21.0602C21.3095 2.07888 21.5485 1.98739 21.7247 1.82453C21.901 1.66167 22 1.44078 22 1.21046C22 0.980142 21.901 0.759255 21.7247 0.596395C21.5485 0.433534 21.3095 0.342041 21.0602 0.342041ZM17.586 6.1314H4.41494C4.16543 6.1314 3.92614 6.22289 3.74971 6.38575C3.57327 6.54861 3.47416 6.7695 3.47416 6.99982C3.47416 7.23014 3.57327 7.45102 3.74971 7.61388C3.92614 7.77675 4.16543 7.86824 4.41494 7.86824H17.586C17.8355 7.86824 18.0748 7.77675 18.2512 7.61388C18.4277 7.45102 18.5268 7.23014 18.5268 6.99982C18.5268 6.7695 18.4277 6.54861 18.2512 6.38575C18.0748 6.22289 17.8355 6.1314 17.586 6.1314ZM9.07055 11.921H12.9302C13.1861 11.921 13.4315 12.0125 13.6125 12.1754C13.7935 12.3383 13.8951 12.5591 13.8951 12.7894C13.8951 13.0198 13.7935 13.2407 13.6125 13.4035C13.4315 13.5664 13.1861 13.6579 12.9302 13.6579H9.07055C8.81464 13.6579 8.56921 13.5664 8.38826 13.4035C8.2073 13.2407 8.10564 13.0198 8.10564 12.7894C8.10564 12.5591 8.2073 12.3383 8.38826 12.1754C8.56921 12.0125 8.81464 11.921 9.07055 11.921Z" fill="#4B5563"/>
                        </svg>
                        <div className={styles.table__calendar}>
                            <span>Янв - Фев 2022</span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 7V3V7ZM16 7V3V7ZM7 11H17H7ZM5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V7C21 6.46957 20.7893 5.96086 20.4142 5.58579C20.0391 5.21071 19.5304 5 19 5H5C4.46957 5 3.96086 5.21071 3.58579 5.58579C3.21071 5.96086 3 6.46957 3 7V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div> 

                <PaginationList process={process}/>
                 
            </div>
        </>
    )
}

export default Pagination;