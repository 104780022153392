import styles from './AccountBlock.module.scss';

const AccountBlock = () => {
    return (
        <>
            <div className={styles.block}>
                <div className={styles.type}>
                    <p className={styles.name}>Сумма пополнения:</p>
                    <p className={styles.ammount}>20 000 ₽</p>
                </div>
                <div className={styles.type}>
                    <p className={styles.name}>Комиссия:</p>
                    <p className={styles.ammount}>
                        12%
                    </p>
                </div>
            </div>
        </>
    )
}

export default AccountBlock;