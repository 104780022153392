import React from 'react'
import { NavLink } from 'react-router-dom';
import styles from './InnerNav.module.scss';


const activeClassName = ({isActive}) => isActive ? `${styles.nav__item} ${styles.active}` : `${styles.nav__item}`;

const InnerNav = ({data}) => {

  return (
    <>
        <nav className={styles.nav}>
            <div className={`wrapper ${styles.nav__wrapper}`}>
              {data.map((item, i) => 
                    (<NavLink key={item+i} to={item.path} className={activeClassName}>{item.title}</NavLink>)
              )}
            </div>
        </nav>
    </>
  )
}


export default InnerNav
