export const accountStatuses = {
    10: 'PERSONAL',// Проект в черновиках
    20: 'STATUS_ON_MODERATE',// На модерации
    25: 'STATUS_MODERATED',// Проект прошел модерацию
    30: 'STATUS_REJECTED',// Проект отклонен, отправлен на доработку

    35: 'STATUS_READY_TO_START',// Проект запущен, ожидает страта компании по сбору средств
    40: 'STATUS_STARTED',// Проект на стадии сбора средств
    50: 'STATUS_WORK',// Проект собрал средства, работает
    60: 'STATUS_PROJECT_SUCCESS',// Проект завершен, срок жизни (работы) проекта закончен

    70: 'STATUS_PROJECT_FAILED',// Проект не набрал достаточно средств

    80: 'STATUS_DELETED',// Проект удален
}
