import PaginationItem from "../PaginationItem/PaginationItem";
import styles from './PaginationList.module.scss';
import { transactionsStore } from "../../stores/TransactionsStore";
import CardsSkeleton from "../Skeleton/CardsSkeleton";

const PaginationList = ({process}) => {
    return (
        <>
            <div className={styles.top}>
                <div className={styles.top__wrapper}>
                    <div className={styles.left}>
                        <div className={styles.top__column}>ID ОПЕРАЦИИ</div>
                        <div className={styles.top__column}>ДАТА проведения</div>
                        <div className={styles.top__column}>СУММА</div>
                        <div className={styles.top__column}>Комиссия</div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.right__column}>Описание операции</div>
                        <div className={styles.right__column}>Дополнительно</div>
                    </div>
                </div>
            </div>
            {process === 'loading' && ([...new Array(3)].map((_, i) => <CardsSkeleton blockHeight={70} key={i}/>))}

            {(process === 'confirmed' && transactionsStore.transactions) && (
                transactionsStore.transactions.map((transaction, i) => {
                    return (
                        <PaginationItem transaction={transaction} key={transaction.id + i}/>
                    )
                })
            )}
            
        </>
    )
}

export default PaginationList;