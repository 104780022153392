import { Link } from 'react-router-dom';
import styles from './Error.module.scss';
import logo from '../../assets/images/big_logo.png';

const Error = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const path = (user && user.profile.businessRole) ? `main/user/${user.profile.businessRole}` : '/settings/profile';

    return (
        <>
            <main className={`main ${styles.main + " " + styles.main__err}`}>
                <div className="wrapper wrapper__error">
                    <h2 className={`component__title main__title ${styles.main_sub}`}>Что-то пошло не так</h2>
                    <div className={styles.block}>
                        <p className={styles.text}>
                            Проверьте правильность написания адреса ссылки по которой вы переходите.
                        </p>
                        <Link to={ (user && user.account.id) ? path : '/'}>
                            <button type='button' className={`component__btn component__btn-big ${styles.button}`}>Вернуться на главную</button>
                        </Link>
                    </div>
                    <img src={logo} alt="Logo" className={styles.logo}/>
                </div>
            </main>
        </>
    )
}

export default Error