import styles from './ProjectBlock.module.scss';

const ProjectBlock = ({sum, percent, year}) => {
    return (
        <>
            <div className={styles.block}>
                <div className={styles.type}>
                    <p className={styles.name}>Сумма займа:</p>
                    <p className={styles.ammount}>{sum !== null ? sum : 0}</p>
                </div>
                <div className={styles.type + ' ' + styles.calc}>
                    <p className={styles.name}>Сумма переплаты:</p>
                    <p className={styles.ammount}>
                        { (sum && percent) ? (`${sum * percent / 100} ₽`) : '0 ₽'}
                    </p>
                </div>
                {year && (<div className={styles.type}>
                    <p className={styles.name}>Годовых:</p>
                    <p className={styles.ammount}>12%</p>
                </div>)}
                <div className={styles.type}>
                    <p className={styles.name}>Комиссия:</p>
                    <p className={styles.ammount}>{percent !== null ? percent + '%' : 0}</p>
                </div>
            </div>
        </>
    )
}

export default ProjectBlock;