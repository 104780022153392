import { Field, ErrorMessage } from 'formik';
import TextError from '../TextError/TextError';
import styles from './Input.module.scss';

const Input = (props) => {
  const { name, type } = props;

    return (
        <>
           <Field name={name}>
             {({ field: {value, ...field}, form: {initialValues}, meta }) => {
              const setInpStyle = (styles) => {
                  let inpStyle = `${styles.inp} `;
                  if (meta.touched && meta.error) {
                      inpStyle += `${styles.inp__err}`;
                  } else if (value) {
                      inpStyle += `${styles.inp__success}`;
                  }
                  return inpStyle
              }
              return (
                <>
                  <input
                    type={type}
                    as={type} 
                    id={name} 
                    name={name} 
                    className={setInpStyle(styles)}
                    value={value}
                    {...field} 
                  />
                  {meta.touched && meta.error && (
                    <ErrorMessage component={TextError} name={name} text={meta.error}/>
                  )}
              </>
              )
             }}
           </Field>
        </>
    )
}

export default Input;