import { makeAutoObservable, action, observable, computed } from "mobx";

class ProjectsStore {

    projects = null;

    constructor() {
        makeAutoObservable(this, {
            projects: observable,
            getProjects: computed,
            setProjects: action,
        });
    }

    get getProjects() {
        return this.projects
    }

    setProjects(projects) {
        this.projects = projects
        console.log(this.projects)
    }
    
}

export const projectsStore = new ProjectsStore();