import { Link } from 'react-router-dom';
import styles from './Error.module.scss';
import logo from '../../assets/images/big_logo.png';

const Error404 = () => {

    const user = JSON.parse(localStorage.getItem("user"));
    const path = (user && user.profile.businessRole) ? `main/user/${user.profile.businessRole}` : '/settings/profile';
    return (
        <>
            <main className={`main ${styles.main}`}>
                <div className="wrapper wrapper__error">
                    <h1 className={`component__title main__title ${styles.main_404}`}>404</h1>
                    <h2 className={`component__title main__title ${styles.main_sub}`}>Вы где?</h2>
                    <div className={styles.block}>
                        <p className={styles.text}>
                            Такой страницы не существует. Вернитесь на главную, где всё просто и стабильно
                        </p>
                        <Link to={ (user && user.account.id) ? path : '/'}>
                            <button type='button' className={`component__btn component__btn-big ${styles.button}`}>Вернуться на главную</button>
                        </Link>
                    </div>
                    <img src={logo} alt="Logo" className={styles.logo}/>
                </div>
            </main>
        </>
  )
}

export default Error404