import { Link } from "react-router-dom";
import styles from './ProjectCard.module.scss';
import { unpublished, published } from "../../variables/projectDuration";

function ProjectCard({item, type}) {
    const className = styles.card + " " + (type === unpublished ? styles.card__attension : type === published ? styles.card__active : null);
    const showStatus = (status) => {
        let statusDescr = '';
        switch (status) {
            case('STATUS_DRAFT') : {
                statusDescr = 'Проект в черновиках';
                break
            }
            case('STATUS_ON_MODERATE') : {
                statusDescr = 'На модерации';
                break
            }
            case('STATUS_MODERATED') : {
                statusDescr = 'Проект прошел модерацию';
                break
            }
            case('STATUS_REJECTED') : {
                statusDescr = 'Проект отклонен или отправлен на доработку';
                break
            }
            case('STATUS_READY_TO_START') : {
                statusDescr = 'Проект запущен, ожидает старта кампании по сбору средств';
                break
            }
            case('STATUS_STARTED') : {
                statusDescr = 'Проект на стадии сбора средств';
                break
            }
            case('STATUS_WORK') : {
                statusDescr = 'Проект собрал средства, работает';
                break
            }
            case('STATUS_PROJECT_SUCCESS') : {
                statusDescr = 'Проект завершен, срок работы завершён';
                break
            }
            case('STATUS_PROJECT_FAILED') : {
                statusDescr = 'Проект не набрал достаточно средств';
                break
            }
            case('STATUS_DELETED') : {
                statusDescr = 'Проект удален';
                break
            }
            default: {
                statusDescr = '';
            }
        }
        return statusDescr
    }

    return (
            <Link 
                to={`/project/loan/${item.id}/info`}
                className={className}
            >
                <div className={styles.card__top}>
                    <h5 className={styles.card__title}>{item.name}</h5>
                    <p className={styles.card__subt}>{showStatus(item.currentStatus)}</p>
                </div>

                <p className={styles.card__sum}>{item.amount + ' ₽'}</p>
            </Link>

    )
}

export default ProjectCard;