import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {Formik, Form} from "formik";
import * as Yup from 'yup';

import AuthService from '../../services/AuthService';
import FormikControl from '../../ComponentsUI/FormikControl/FormikControl';
import Modal from '../../ComponentsUI/Modal/Modal';

import styles from './Signup.module.scss';



const Signup = () => {

    const [isOpen, setIsOpen] = useState(false);
    const {postData, error, process, setProcess} = AuthService();
    const returnLegend = () => {
        return (
            <>  
                <span>Я соглашаюсь с </span><a href="#">политикой конфиденциальности</a>
            </>
        )
    };
    const initialValues  ={
            email: '',
            username : '',
            password: '',
            passwordConfirm: '',
            acceptTerms: false
        }
    const validationSchema = Yup.object().shape({
            email: Yup.string().required('Введите адрес почты').email('Некорректный адрес почты'),
            username: Yup.string().required('Введите логин').min(4, 'Длина логина должна быть не менее 4 знаков'),
            password: Yup.string().required('Введите пароль').min(4, 'Длина пароля должна быть не менее 4 знаков'),
            passwordConfirm: Yup.string().required('Подтвердите пароль').oneOf([Yup.ref("password"), null], "Пароли не совпадают"),
            acceptTerms: Yup.bool().oneOf([true], "Необходимо подтвердить согласие с политикой конфиденциальности"),
        });

    const handleSubmit = async (values) => {

        const loginPayload = {
            login: values.username,
            password: values.password,
            passwordConfirm: values.passwordConfirm,
            email: values.email
        }

        console.log(loginPayload)

        postData("auth/register", loginPayload)
            .then (response => {
                    console.log(response.data)
                    setIsOpen(true)
                    })
            .then(() => setProcess('confirmed'));
    }

    const navigate = useNavigate();

    return (
            <>  
                <Modal handleClose={() => setIsOpen(false)} isOpen={isOpen} >
                    <h3>Авторизация прошла успешно!</h3>
                    <p>Для перехода в личный кабинет необходимо атвориховаться
                    </p>
    
                    <div>
                        <button
                            className="component__btn component__btn-big component__btn-submit"
                            type="button"
                            onClick={() => {setIsOpen(false); navigate('/login')}}>Войти в аккаунт</button>
                    </div>
                </Modal>
                
                <h2 className="component__title main__title">Регистрация</h2>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit= {async (values, { resetForm }) => {
                        const resp = await handleSubmit(values)
                         if (resp) {
                            resetForm()
                         }
                    }}
                >
                    {() => {
                        return (
                                <Form className={styles.form}>
                                    <div className={styles.block}>
                                        <p className="component__subt">почта</p>
                                        <div className={styles.block__wrapper}>
                                            <FormikControl control="input" name="email" type="email"/>
                                        </div>
                                    </div>
                
                                    <div className={styles.block}>
                                        <p className="component__subt">логин</p>
                                        <div className={styles.block__wrapper}>
                                            <FormikControl control="input" name="username" type="text"/>
                                        </div>
                                    </div>
                
                                    <div className={styles.block}>
                                        <p className="component__subt">пароль</p>
                                        <div className={styles.block__wrapper}>
                                            <FormikControl control="input" name="password" type="password"/>
                                            
                                            {/* <svg className={styles.block__show} xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="20" height="20">
                                                <path d="M23.271,9.419A15.866,15.866,0,0,0,19.9,5.51l2.8-2.8a1,1,0,0,0-1.414-1.414L18.241,4.345A12.054,12.054,0,0,0,12,2.655C5.809,2.655,2.281,6.893.729,9.419a4.908,4.908,0,0,0,0,5.162A15.866,15.866,0,0,0,4.1,18.49l-2.8,2.8a1,1,0,1,0,1.414,1.414l3.052-3.052A12.054,12.054,0,0,0,12,21.345c6.191,0,9.719-4.238,11.271-6.764A4.908,4.908,0,0,0,23.271,9.419ZM2.433,13.534a2.918,2.918,0,0,1,0-3.068C3.767,8.3,6.782,4.655,12,4.655A10.1,10.1,0,0,1,16.766,5.82L14.753,7.833a4.992,4.992,0,0,0-6.92,6.92l-2.31,2.31A13.723,13.723,0,0,1,2.433,13.534ZM15,12a3,3,0,0,1-3,3,2.951,2.951,0,0,1-1.285-.3L14.7,10.715A2.951,2.951,0,0,1,15,12ZM9,12a3,3,0,0,1,3-3,2.951,2.951,0,0,1,1.285.3L9.3,13.285A2.951,2.951,0,0,1,9,12Zm12.567,1.534C20.233,15.7,17.218,19.345,12,19.345A10.1,10.1,0,0,1,7.234,18.18l2.013-2.013a4.992,4.992,0,0,0,6.92-6.92l2.31-2.31a13.723,13.723,0,0,1,3.09,3.529A2.918,2.918,0,0,1,21.567,13.534Z"/>
                                            </svg> */}
                
                                        </div>
                                    </div>
                
                                    <div className={styles.block}>
                                        <p className="component__subt">подтвердить пароль</p>
                                        <div className={styles.block__wrapper}>
                                            <FormikControl control="input" name="passwordConfirm" type="password"/>
                                            
                                            {/* <svg className={styles.block__show} xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="20" height="20">
                                                <path d="M23.271,9.419A15.866,15.866,0,0,0,19.9,5.51l2.8-2.8a1,1,0,0,0-1.414-1.414L18.241,4.345A12.054,12.054,0,0,0,12,2.655C5.809,2.655,2.281,6.893.729,9.419a4.908,4.908,0,0,0,0,5.162A15.866,15.866,0,0,0,4.1,18.49l-2.8,2.8a1,1,0,1,0,1.414,1.414l3.052-3.052A12.054,12.054,0,0,0,12,21.345c6.191,0,9.719-4.238,11.271-6.764A4.908,4.908,0,0,0,23.271,9.419ZM2.433,13.534a2.918,2.918,0,0,1,0-3.068C3.767,8.3,6.782,4.655,12,4.655A10.1,10.1,0,0,1,16.766,5.82L14.753,7.833a4.992,4.992,0,0,0-6.92,6.92l-2.31,2.31A13.723,13.723,0,0,1,2.433,13.534ZM15,12a3,3,0,0,1-3,3,2.951,2.951,0,0,1-1.285-.3L14.7,10.715A2.951,2.951,0,0,1,15,12ZM9,12a3,3,0,0,1,3-3,2.951,2.951,0,0,1,1.285.3L9.3,13.285A2.951,2.951,0,0,1,9,12Zm12.567,1.534C20.233,15.7,17.218,19.345,12,19.345A10.1,10.1,0,0,1,7.234,18.18l2.013-2.013a4.992,4.992,0,0,0,6.92-6.92l2.31-2.31a13.723,13.723,0,0,1,3.09,3.529A2.918,2.918,0,0,1,21.567,13.534Z"/>
                                            </svg> */}
                                        </div>
                                        
                                    </div>
                
                                    <div className={styles.form__submit}>
                                        <FormikControl 
                                            control="checkbox"
                                            name="acceptTerms"
                                            legend={returnLegend()}
                                        />
                                     
                                        <button type="submit" className="component__btn component__btn-big">Зарегистрироваться</button>
                                    </div>
                                </Form>
                        )
                    }}
                </Formik>
        </>
    )
}

export default Signup