import { makeAutoObservable, action, observable, computed } from "mobx";

class TransactionsStore {

    transactions = null;

    constructor() {
        makeAutoObservable(this, {
            transactions: observable,
            getTransactions: computed,
            setTransactions: action,
        });
    }

    get getTransactions() {
        return this.transactions
    }

    setTransactions(transactions) {
        this.transactions = transactions
    }
    
}

export const transactionsStore = new TransactionsStore();