import { makeAutoObservable, action, observable, computed } from "mobx";

class UserStore {

    userData = null;

    constructor() {
        makeAutoObservable(this, {
            userData: observable,
            getUserData: computed,
            setUserData: action,
            getUserDataFromServer: action
        });
    }

    get getUserData() {
        return this.userData
    }

    setUserData(userData) {
        this.userData = userData
        console.log(this.userData)
    }
    
}

export const userStore = new UserStore();