import { Field, ErrorMessage } from 'formik';
import TextError from '../TextError/TextError';
import styles from './TextArea.module.scss';

const TextArea = (props) => {
  const { name } = props;

    return (
        <>
           <Field name={name}>
             {({ field: {value, ...field}, form: {initialValues}, meta }) => {
              const setInpStyle = (styles) => {
                  let inpStyle = `${styles.area} `;
                  if (meta.touched && meta.error) {
                      inpStyle += `${styles.area__err}`;
                  } else if (value) {
                      inpStyle += `${styles.area__success}`;
                  }
                  return inpStyle
              }

              return (
                <>
                <textarea 
                   type="text"
                   as='textarea' 
                   id={name} 
                   name={name} 
                   className={setInpStyle(styles)}
                   value={value}
                   {...field} 
                />
                {meta.touched && meta.error && (
                  <ErrorMessage component={TextError} name={name} text={meta.error}/>
                )}
              </>
              )
             }}
           </Field>
        </>
    )
}

export default TextArea;