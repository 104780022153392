import { useState } from "react";
import { Link } from "react-router-dom";

import Navbar from "../Navbar/Navbar";
import { useMatchMedia } from "../../utils/useMatchMedia";

import styles from './Header.module.scss';
import logo from '../../assets/images/logo.png';
import avatar from '../../assets/images/avatar.png';
import MenuBurger from "../MenuToggler/MenuBurger";

const Header = ({menuState, setMenuState, handleLogout, isLogged}) => {

    const [showMenu, setShowMenu] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const {isNarrowScreen} = useMatchMedia();
    
    const toggleMenu = () => {
        setMenuState(!menuState)
    }

    const toggleSearch = () => {
        setSearchOpen(!searchOpen);
    }
    const user = JSON.parse(localStorage.getItem("user"));
    const logoPath = isLogged ? `/user/${user.profile.businessRole}` : '/login';
    const searchClassName = searchOpen ? `${styles.header__search} ${styles.search__open}` : `${styles.header__search}`

    return (
        <>
            <header className={styles.header}>
                <div className={`wrapper + ${styles.header__wrapper}`}>
                    <div className={styles.header__left}>
                     {isNarrowScreen ? <MenuBurger menuState={menuState} toggleMenu={toggleMenu}/> : null}
                    
                    <Link className={styles.logo} to={logoPath}>
                        <img src={logo} alt="Logo" className={styles.logo__img} />
                        <span className={styles.logo__text}>Объединённая платформа</span>
                    </Link>

                    </div>

                    <div className={styles.header__center}>

                       {isLogged && 
                                    (<div className={searchClassName}>
                                        <button className={styles.search__btn} onClick={toggleSearch}>
                                            <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512" fill="#767783">
                                                <path d="M23.707,22.293l-5.969-5.969a10.016,10.016,0,1,0-1.414,1.414l5.969,5.969a1,1,0,0,0,1.414-1.414ZM10,18a8,8,0,1,1,8-8A8.009,8.009,0,0,1,10,18Z"/>
                                            </svg>
                                        </button>
                                        <input className={styles.search__inp} type="text" placeholder="Поиск"/>
                                    </div>)
                        } 
                    </div>

                    <div className={styles.header__right}>
                        {(!user) ? (<div className={styles.user}>
                                        <Link to="signup">
                                            <button className={`component__btn component__btn-small ${styles.user__signup}`}>Регистрация</button>
                                        </Link>
                                        
                                        <Link to="/">
                                            <svg onClick={handleLogout} className={styles.user__signin} width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M7.27044 0.227035C7.41471 0.0816741 7.61039 0 7.81443 0C10.1017 0 12.2953 0.915435 13.9126 2.54493C15.53 4.17442 16.4386 6.38449 16.4386 8.68895C16.4386 10.9934 15.53 13.2035 13.9126 14.833C12.2953 16.4625 10.1017 17.3779 7.81443 17.3779C7.61039 17.3779 7.41471 17.2962 7.27044 17.1509C7.12616 17.0055 7.04511 16.8084 7.04511 16.6028C7.04511 16.3972 7.12616 16.2001 7.27044 16.0547C7.41471 15.9094 7.61039 15.8277 7.81443 15.8277C9.69362 15.8277 11.4958 15.0756 12.8246 13.7368C14.1534 12.398 14.8999 10.5823 14.8999 8.68895C14.8999 6.79564 14.1534 4.97987 12.8246 3.6411C11.4958 2.30232 9.69362 1.55021 7.81443 1.55021C7.61039 1.55021 7.41471 1.46854 7.27044 1.32318C7.12616 1.17782 7.04511 0.980679 7.04511 0.775107C7.04511 0.569536 7.12616 0.372396 7.27044 0.227035ZM10.1324 9.13503L7.57866 11.9408C7.51473 12.0183 7.43594 12.0823 7.34692 12.1288C7.2579 12.1753 7.16048 12.2034 7.06041 12.2116C6.87426 12.2111 6.69492 12.1414 6.55717 12.016C6.41007 11.8822 6.32193 11.6954 6.31208 11.4966C6.30222 11.2978 6.37145 11.1032 6.50459 10.9554L7.90915 9.40582H0.751107C0.551901 9.40582 0.360853 9.32658 0.219993 9.18551C0.0791336 9.04444 0 8.8531 0 8.6536C0 8.4541 0.0791336 8.26278 0.219993 8.12171C0.360853 7.98065 0.551901 7.90138 0.751107 7.90138H7.8716L6.51209 6.44961C6.3722 6.30867 6.29368 6.11803 6.29368 5.91931C6.29368 5.72058 6.3722 5.52992 6.51209 5.38898C6.58192 5.31848 6.66499 5.26253 6.75652 5.22434C6.84805 5.18615 6.94623 5.16649 7.04538 5.16649C7.14454 5.16649 7.24272 5.18615 7.33425 5.22434C7.42578 5.26253 7.50884 5.31848 7.57866 5.38898L10.1324 8.112C10.2612 8.2511 10.3328 8.43382 10.3328 8.62353C10.3328 8.81324 10.2612 8.99593 10.1324 9.13503Z" fill="#070D3F"/>
                                            </svg>
                                        </Link>
                                    </div>) 
                       : (<div 
                            className={styles.user}
                            onClick={() => setShowMenu(!showMenu)}
                        >
                            <p className={styles.user__name}>{user.account.login}</p>
                            <img src={avatar} alt="Avatar" className={styles.user__img}/>
                        </div>)}
                            {showMenu ? 
                                        (<div className={styles.acсount__full}>
                                            <div className={styles.account__menu}>
                                                <Link to={logoPath} onClick={() => setShowMenu(!showMenu)}>Личный кабинет</Link>
                                                <Link to={logoPath} onClick={() => setShowMenu(!showMenu)}>Мои проекты</Link>
                                                <a href="#" onClick={() => setShowMenu(!showMenu)}>Поддержка</a>
                                            </div>
                                            <div onClick={() => {handleLogout(); setShowMenu(!showMenu)}} className={styles.account__logout}>
                                                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.27006 0.227035C7.41434 0.0816741 7.61002 0 7.81405 0C10.1013 0 12.2949 0.915435 13.9122 2.54493C15.5296 4.17442 16.4382 6.38449 16.4382 8.68895C16.4382 10.9934 15.5296 13.2035 13.9122 14.833C12.2949 16.4625 10.1013 17.3779 7.81405 17.3779C7.61002 17.3779 7.41434 17.2962 7.27006 17.1509C7.12578 17.0055 7.04473 16.8084 7.04473 16.6028C7.04473 16.3972 7.12578 16.2001 7.27006 16.0547C7.41434 15.9094 7.61002 15.8277 7.81405 15.8277C9.69324 15.8277 11.4955 15.0756 12.8242 13.7368C14.153 12.398 14.8995 10.5823 14.8995 8.68895C14.8995 6.79564 14.153 4.97987 12.8242 3.6411C11.4955 2.30232 9.69324 1.55021 7.81405 1.55021C7.61002 1.55021 7.41434 1.46854 7.27006 1.32318C7.12578 1.17782 7.04473 0.980679 7.04473 0.775107C7.04473 0.569536 7.12578 0.372396 7.27006 0.227035ZM0.200384 8.24301L2.75415 5.43723C2.81808 5.3597 2.89688 5.29578 2.98589 5.24927C3.07491 5.20276 3.17233 5.1746 3.27241 5.16645C3.45856 5.16693 3.63789 5.23664 3.77565 5.36203C3.92275 5.49588 4.01088 5.68265 4.02074 5.88145C4.03059 6.08025 3.96137 6.27485 3.82823 6.42264L2.42366 7.97222L9.58171 7.97222C9.78091 7.97222 9.97196 8.05146 10.1128 8.19253C10.2537 8.3336 10.3328 8.52494 10.3328 8.72444C10.3328 8.92394 10.2537 9.11526 10.1128 9.25633C9.97196 9.3974 9.78091 9.47666 9.58171 9.47666L2.46121 9.47666L3.82072 10.9284C3.96062 11.0694 4.03913 11.26 4.03913 11.4587C4.03913 11.6575 3.96062 11.8481 3.82072 11.9891C3.7509 12.0596 3.66782 12.1155 3.57629 12.1537C3.48477 12.1919 3.38659 12.2115 3.28743 12.2115C3.18828 12.2115 3.0901 12.1919 2.99857 12.1537C2.90704 12.1155 2.82398 12.0596 2.75415 11.9891L0.200384 9.26604C0.0715725 9.12694 1.65855e-08 8.94422 0 8.75451C-1.65855e-08 8.56481 0.0715725 8.38211 0.200384 8.24301Z" fill="#070D3F"/>
                                                </svg>
                                                <Link to="/">Выход</Link>
                                            </div>
                                        </div>)
                                        : null
                            }
                    </div>

                    <Navbar menuState={menuState} setMenuState={setMenuState}/>
                </div>
            </header>
        </>
    )
}

export default Header;