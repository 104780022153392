import { useLocation, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import arrowBtn from '../../../assets/images/arrow-left.png';

import { chooseTitle, chooseType } from '../logic';
import styles from './../DashboardTop.module.scss';

function DashboardCrumbs({accounts, projects, user}) {
    const {id, accId} = useParams();

    let location = useLocation();
    const page = location.pathname;

    return (
        <div className={styles.navigation}>

            {(location.pathname.indexOf('/main') === -1) 
                && (
                <Link to={`/main/user/${user.profile.businessRole}`} className={styles.navigation__block}>
                    <img src={arrowBtn} alt="Back"/>
                        Назад на главную
                </Link>)}

                {location.pathname.indexOf(`account-settings`) !== -1 
                    &&  (<div className={styles.navigation__block + ' ' + styles.navigation__current}>{chooseTitle(page, user, accounts, accId)}</div>)
                }

                {(location.pathname.indexOf(`project`) !== -1) 
                    && (<>
                            <Link to={`/project/${chooseType(user)}/${id}/info`} className={styles.navigation__block + ' ' + styles.navigation__current}>{chooseTitle(page, user, projects, id)}</Link>
                            {(location.pathname.indexOf(`edit`) !== -1) 
                            && (<div className={styles.navigation__block + ' ' + styles.navigation__current}>
                                    Редактирование проекта
                                </div>)}
                    </>
                )}

                {(location.pathname.indexOf(`account-settings`) === -1 &&
                    location.pathname.indexOf(`project`) === -1 &&
                    location.pathname.indexOf('/main') === -1) &&
                    (<div className={styles.navigation__block + ' ' + styles.navigation__current}>{chooseTitle(page, user)}</div>)
            }
        </div>
    )
}

export default DashboardCrumbs