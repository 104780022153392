import { useState } from 'react';

import InnerNav from '../../ComponentsUI/InnerNav/InnerNav';
import ProjectForm from './ProjectForm';
import Loader from '../../ComponentsUI/Loader/Loader';
import styles from './Project.module.scss';

const NewProject = () => {

    const [project, setProject] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const data = [
        { title: 'Данные', },
        // {path: 'delete', title: 'Удалить'}
    ]

    return (
        <>
            <InnerNav data={data}/>
            <div className={styles.container}>
                {(!isLoading) 
                    ?  <div className={`wrapper ${styles.new__wrapper}`}>
                            <ProjectForm project={project} setProject={setProject}/>
                        </div>
                    :   <Loader/>}
            </div>
        </>
    )
}

export default NewProject;