import { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form} from 'formik';

import AuthService from '../../services/AuthService';

import styles from './NewAccount.module.scss';

import Modal from '../../ComponentsUI/Modal/Modal';
import FormikControl from '../../ComponentsUI/FormikControl/FormikControl';
import DashboardInfo from '../../ComponentsUI/DashboardTop/DashboardInfo/DashboardInfo';

const NewAccount = () => {

    const [showModal, setShowModal] = useState(false)
    const storedData = JSON.parse(localStorage.getItem("user"));
    const { postData, process, setProcess } = AuthService();

    const initialValues = {
        name: ''
    }
    const validationSchema = Yup.object({
        name: Yup.string().required('Введите имя'),
    });

    const handleSubmit = (values) => {
        const accountName = {
            name: values.name,
        }

        postData("payments/accounts", accountName)
            .then(() => setProcess('confirmed'))
            .then ( response => {
                setShowModal(true)
            })
    }


    return (
        <>
            <Modal handleClose={() => setShowModal(false)} isOpen={showModal} >
                {process === 'confirmed' 
                    &&  (<>
                            <h3>Счёт успешно создан</h3>
                            <p>Можете вернуться в профиль</p>
                            <div>
                                <Link to={`/accounts`}>
                                    <button 
                                        onClick={() => setShowModal(false)}
                                        className="component__btn component__btn-big component__btn-submit"
                                    >
                                        На главную</button>
                                </Link>
                            </div>
                        </>
                            )}
                {process === `error` 
                    &&  (<>
                            <h3>Возникла ... ошибка</h3>
                            <p>Попробуйте ещё раз</p>
                            <div>
                                <button 
                                    onClick={() => setShowModal(false)}
                                    className="component__btn component__btn-big component__btn-cancel"
                                >
                                    Попробовать снова</button>
                            </div>
                        </>)
                }
            </Modal>
            <DashboardInfo/>
            <div className={styles.container}>
                <div className={`wrapper ${styles.new__wrapper}`}>
                    <div className={`component__union`}>
                        <div className="component__union-type">
                            <h5 className="component__union-title">Необходимо указать название счёта</h5>
                            <p className="component__union-descr">Описание</p>
                        </div>

                        <Formik
                            initialValues = {initialValues}
                            validationSchema = {validationSchema}
                            enableReinitialize
                            onSubmit= {async (values, { resetForm }) => {
                                    const resp = await handleSubmit(values)
                                    if (resp) {
                                        resetForm()
                                    }
                            }}     
                            >
                            {() => {
                                
                                return (
                                    <Form action='post' className={`${styles.block} component__block`} >
                                        <div className={`${styles.block__main} component__block-main`}>
                                            <div className={styles.form__block}>
                                                <p className="component__subt">Название счёта</p>
                                                <div className={styles.block__wrapper}>
                                                    <FormikControl
                                                        control="input"
                                                        type="text" 
                                                        name="name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="component__block-submit">
                                            <button type="submit" className="component__btn component__btn-small">Сохранить</button>
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewAccount