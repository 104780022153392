import styles from './TextError.module.scss';

const TextError = (props) => {

    const {text} = props
    return (
        <>
            <div className={styles.error__message}>{text}</div>
        </>
    )
}

export default TextError;