import FormikControl from '../../../ComponentsUI/FormikControl/FormikControl';
import AccountBlock from '../../../ComponentsUI/AccountBlock/AccountBlock';

import { Formik, Field, ErrorMessage, Form} from 'formik';
import * as Yup from 'yup';
import styles from './../AccountSettings.module.scss';

const SettingsTopup = () => {

    const dropdownOptions = [
        { label: 'Visa/MasterCard', value: 'Visa/MasterCard' },
        { label: 'Мир', value: 'Мир' },
        { label: 'Qiwi', value: 'Qiwi' }
    ]
    const initialValues = {
        selectOption: '',
        loanAmount: null
    }
    const validationSchema = Yup.object({
      selectOption: Yup.string().required('Необходимо выбрать способ пополнения'),
      loanAmount: Yup.number().required('Необходимо ввести сумму')
    });

    const handleSubmit = (values) => {
        console.log(values.selectOption)
    }

    return (
        <>  
            <div className={`component__union`}>
                <div className="component__union-type">
                    <h5 className="component__union-title">Пополнить счёт</h5>
                    <p className="component__union-descr">Описание</p>
                </div>

                <div className={`${styles.block} component__block`}>
                    <div className={`${styles.block__main} component__block-main`}>
                        <h5 className="component__union-title">Введите данные для пополнения</h5>
                        
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            enableReinitialize
                            onSubmit= {async (values, { resetForm }) => {
                                const resp = await handleSubmit(values)
                                if (resp) {
                                    resetForm()
                                }
                            }}
                        >
                            {({values}) => {
                                return (
                                    <Form>
                                        <div>
                                        <div className={styles.form__block}>
                                                <p className="component__subt">способ пополнения</p>
                                                <div className={styles.block__wrapper}>
                                                    <FormikControl
                                                        control = "select"
                                                        options={dropdownOptions}
                                                        name="selectOption" 
                                                        value={values.selectOption}
                                                        placeholder="Введите способ пополнения"
                                                    />
                                                </div>
                                            </div>

                                            <div className={styles.form__block}>
                                                <p className="component__subt">Сумма</p>
                                                <div className={styles.block__wrapper}>
                                                    <FormikControl
                                                        control="input"
                                                        type="number" 
                                                        name="loanAmount"
                                                    />
                                                </div>
                                            </div>
            
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>

                        <div className={styles.form__block}>
                                                <div className={styles.block__wrapper}>
                                                        <AccountBlock/>
                                                </div>
                        </div>
                    </div>
                    <div className={`component__block-submit ${styles.submit__block}`}>
                            <button 
                                type="submit" 
                                className="component__btn component__btn-small"
                                >
                                    Пополнить
                            </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SettingsTopup