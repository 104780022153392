import { useState, useEffect } from 'react';
import { useParams, Outlet } from 'react-router-dom';

import InnerNav from '../../ComponentsUI/InnerNav/InnerNav';

import DataService from '../../services/DataService';
import AuthService from '../../services/AuthService';
import styles from './Project.module.scss'
import Loader from '../../ComponentsUI/Loader/Loader';

const CurrentProject = () => {

    const { _transformProject } = DataService();
    const {getData, process, setProcess, setError} = AuthService();
    const [project, setProject] = useState(null);
    const {id} = useParams();

    useEffect(() => {
        getProject();
    }, []);

    const data = [
        { title: 'Данные', },
    ]

    const getProject = async () => {
            getData(`project/${id}`)
                .then(response => {
                    onProjectLoaded(response.data.project)
                })
                .then(() => setProcess('confirmed'))      
    }

    const onProjectLoaded = (data) => {
        let projectData = _transformProject(data);
        setProject(projectData)
        console.log(project)
    }

    return (
        <>
            {process === 'confirmed' && (project && 
                                      (project.currentStatus === 'STATUS_DRAFT' 
                                    || project.currentStatus === 'STATUS_ON_MODERATE' 
                                    || project.currentStatus === 'STATUS_MODERATED'
                                    || project.currentStatus === 'STATUS_REJECTED')) &&
                (<InnerNav data={data}/>)
            }
            <div className={styles.container}>

                <div className={`wrapper ${styles.new__wrapper}`}>
                    <Outlet context={[project, setProject, process, setProcess, setError]}/>            
                </div>
                
            {process === 'loading' && <Loader/>}
            </div>
        </>
    )
}

export default CurrentProject;