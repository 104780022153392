import { Link } from "react-router-dom";
import AuthService from "../../services/AuthService";
import DataService from "../../services/DataService";
import { accountsStore } from "../../stores/AccountsStore";

import DashboardInfo from "../../ComponentsUI/DashboardTop/DashboardInfo/DashboardInfo";
import AccountPanel from "../../ComponentsUI/Panels/AccountPanel/AccountPanel";
import AccountCard from "../../ComponentsUI/AccountCard/AccountCard";
import CardsSkeleton from "../../ComponentsUI/Skeleton/CardsSkeleton";
import Pagination from "../../ComponentsUI/Pagination/Pagination";

import styles from './Accounts.module.scss';
import { useEffect } from "react";

const Accounts = () => {
    const { getData, setProcess, process } = AuthService();
    const { _transformAccounts } = DataService();

    useEffect(() => {
        setProcess('loading')
        getAccounts()
    }, []);

    const getAccounts = async () => {
        const response = getData('payments/accounts')
                .then(response => {
                    console.log(response)
                    if (response.data.status === 'ok') {
                        onAccountsLoaded(response.data)
                    } else {
                        accountsStore.setAccounts([])
                    }
                })
                .then(() => setProcess('confirmed')) 
    }

    const onAccountsLoaded = (response) => {
        let accounts = response.list.map(_transformAccounts)
        console.log(accounts)
        accountsStore.setAccounts(accounts)
        localStorage.setItem("accounts", JSON.stringify(accounts))
        window.scrollTo(0,0)
    }

    return (
        <>
            <DashboardInfo/>
            <div>
                <AccountPanel process={process}/>
                <div className={`wrapper ${styles.accounts__wrapper}`}>
                    <div className={styles.accounts}>
                        <div className={styles.accounts__top}>
                            <div className={styles.accounts__left}>
                                <h2 className={styles.accounts__title}>Список ваших счетов</h2>
                                <p className={styles.accounts__subt}>Список счетов, которые у вас открыты. Включая основной и дополнительные.</p>
                            </div>
                            
                            <div className={styles.accounts__right}>

                                <Link to="/new-account" className={styles.accounts__btn}>
                                    Открыть счёт
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M26.25 15H3.75" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M15 26.25L15 3.75" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </Link>

                                <Link to="" className={styles.accounts__btn}>
                                    Пополнить счёт
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14 15L1 15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M15 15.25L9.75 10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M15 15.25L9.75 20.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.5 25.5H19.5C22.2614 25.5 24.5 23.2614 24.5 20.5V9.5C24.5 6.73858 22.2614 4.5 19.5 4.5H13.5" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    <circle cx="19" cy="10" r="2" stroke="white" strokeWidth="2"/>
                                    </svg>
                                </Link>

                                <Link to="" className={styles.accounts__btn}>
                                    Перевести на счёт
                                    <svg width="26" height="12" viewBox="0 0 26 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M24.25 6H1.75M19.25 1L24.25 6L19.25 1ZM24.25 6L19.25 11L24.25 6Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </Link>
                            </div>
                        </div>

                        <div className={styles.accounts__list}>
                            {process === 'loading' && ([...new Array(6)].map((_, i) => <CardsSkeleton blockHeight={170} key={i}/>))}
                            {(process === 'confirmed' && accountsStore.accounts) 
                                && (accountsStore.accounts.map((account, i) => {
                                    return (
                                        <AccountCard account={account} key={account.title + ' ' + i}/>
                                    )
                                }))}
                        </div>
                    </div>

                    <div className={styles.history}>
                        <h2 className={styles.accounts__title}>Список последних операций</h2> 
                        <Pagination/>
                    </div>          
                </div>
            </div>
        </>
    )
}

export default Accounts;