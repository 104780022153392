import {Outlet} from "react-router-dom";

import Footer from "../../ComponentsUI/Footer/Footer";

import styles from './DashboardLayout.module.scss';

const DashboardLayout = (props) => {

    return (
        <>
            <main className={`main ${styles.main}`}>
                <Outlet />
            </main>
            <Footer/>
        </>
    );
}

export default DashboardLayout;