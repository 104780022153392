import styles from './Settings.module.scss';

const RequisitesSettings = () => {
    return (
        <>
            <div>
                <div className={`wrapper ${styles.settings__wrapper}`}>
                    <div className="component__union">
                        <div className="component__union-type">
                            <h5 className="component__union-title">Реквизиты ваших счетов</h5>
                            <p className="component__union-descr">
                                            Выберите необходимый счёт
                            </p>
                        </div>

                        <div className={`${styles.block} component__block`}>
                            <div className={`${styles.block__main} component__block-main`}>
                                <h6 className="component__block-title">Активный счёт</h6>

                                <div className={styles.card}>
                                    <div className={styles.card__top}>
                                        <p className={styles.card__number}>1999 **** **** 4477</p>
                                        <div className={styles.card__balance}>₽ 2.000.345</div>
                                    </div>
                                    <div className={styles.card__bottom}>
                                        <p className={styles.card__date}>Счёт октрыт <span>24 марта 2022</span></p>
                                        <div className={styles.card__btns}>
                                            <button type="button" className={`component__btn component__btn-small ${styles.card__btn}`}>Удалить</button>
                                            <button type="button" className={`component__btn component__btn-small ${styles.card__btn}`}>Выбрать</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="component__block-submit">
                                <button className="component__btn component__btn-small">Выйдите из других сеансов Браузера</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RequisitesSettings;