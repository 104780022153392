import styles from './MenuBurger.module.scss';

const MenuBurger = ({menuState, toggleMenu}) => {

    let burgerInner = null;

    burgerInner = menuState 
    ?   (
            <>   
                <div className={styles.close__item}></div>
                <div className={styles.close__item}></div>
            </>
        )
    :   (
            <>
                <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5ZM3 7.5C3 8.32843 2.32843 9 1.5 9C0.671573 9 0 8.32843 0 7.5C0 6.67157 0.671573 6 1.5 6C2.32843 6 3 6.67157 3 7.5ZM1.5 15C2.32843 15 3 14.3284 3 13.5C3 12.6716 2.32843 12 1.5 12C0.671573 12 0 12.6716 0 13.5C0 14.3284 0.671573 15 1.5 15ZM10 1.5C10 2.32843 9.32843 3 8.5 3C7.67157 3 7 2.32843 7 1.5C7 0.671573 7.67157 0 8.5 0C9.32843 0 10 0.671573 10 1.5ZM8.5 9C9.32843 9 10 8.32843 10 7.5C10 6.67157 9.32843 6 8.5 6C7.67157 6 7 6.67157 7 7.5C7 8.32843 7.67157 9 8.5 9ZM10 13.5C10 14.3284 9.32843 15 8.5 15C7.67157 15 7 14.3284 7 13.5C7 12.6716 7.67157 12 8.5 12C9.32843 12 10 12.6716 10 13.5ZM15.5 3C16.3284 3 17 2.32843 17 1.5C17 0.671573 16.3284 0 15.5 0C14.6716 0 14 0.671573 14 1.5C14 2.32843 14.6716 3 15.5 3ZM17 7.5C17 8.32843 16.3284 9 15.5 9C14.6716 9 14 8.32843 14 7.5C14 6.67157 14.6716 6 15.5 6C16.3284 6 17 6.67157 17 7.5ZM15.5 15C16.3284 15 17 14.3284 17 13.5C17 12.6716 16.3284 12 15.5 12C14.6716 12 14 12.6716 14 13.5C14 14.3284 14.6716 15 15.5 15Z" fill="#070D3F"/>
                </svg>
            </>
        );

    return (
        <>
            <div className={styles.burger} onClick={toggleMenu}>
                {burgerInner}
            </div>
        </>
    )
}

export default MenuBurger;