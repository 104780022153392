import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthService from "../../services/AuthService";
import DataService from "../../services/DataService";
import DashboardInfo from "../../ComponentsUI/DashboardTop/DashboardInfo/DashboardInfo";
import InnerNav from "../../ComponentsUI/InnerNav/InnerNav";
import styles from './AccountSettings.module.scss';

const AccountSettings = () => {
    const [account, setAccount] = useState();
    const { getData, setProcess } = AuthService();
    const { _transformAccounts } = DataService();

    let navigate = useNavigate();
    const {accId} = useParams();
    console.log(accId)

    useEffect(() => {
        getAccount()
        return navigate("topup");
    }, []);

    const getAccount = async () => {
        getData(`payments/accounts/${accId}`)
            .then(response => {
                onAccountLoaded(response.data.account)
            })
            .then(() => setProcess('confirmed'))      
}

const onAccountLoaded = (data) => {
    let accountData = _transformAccounts(data);
    setAccount(accountData)
    console.log(accountData)
}


    const data = [
                    {path: 'topup', title: 'Пополнить'},
                    {path: 'transfer', title: 'Перевести'},
                    {path: 'params', title: 'Параметры'}
                ]
    return (
        <>
            <DashboardInfo/>
            <InnerNav data={data}/>
            <div className={styles.container}>
                <div className={`wrapper ${styles.new__wrapper}`}>
                    <Outlet/>
                </div>
            </div>
        </>
    )
}

export default AccountSettings;