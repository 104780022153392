import { useState } from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from '../TextError/TextError';
import styles from './Checkbox.module.scss';

const Checkbox = (props) => {
  const {name, legend} = props;

  const [check, setCheck] = useState(false);
  return (
        <>
          <Field name={name}>
             {({
               field,
               form,
               meta,
             }) => (
               <>
                <div className={styles.wrapper}>
                  <input 
                        type="checkbox" 
                        className={styles.checkbox} 
                        id={name} 
                        name={name}
                        defaultChecked={check}
                        {...field} 
                    />
                    <label 
                        htmlFor={name} 
                        className={styles.label} 
                        onClick={() => setCheck(!check)}
                    >
                        <div className={styles.label__block}></div>
                        <legend className={styles.legend}>{legend}</legend>
                    </label>
                    {meta.touched && meta.error && (
                        <ErrorMessage component={TextError} name={name} text={meta.error}/>
                    )}
                </div>
               </>
             )}
          </Field>
        </>
  )
}

export default Checkbox;