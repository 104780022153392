import styles from './Skeleton.module.scss';

const ProjectSkeleton = ({blockHeight}) => {

  const heightStyle = {height: `${blockHeight}px`}
  return (
    <>
      <div className={`${styles.block} component__block`}>
            <div className={`${styles.block__main} component__block-main`}>
                <h3 className={styles.title}>
                    <div className={styles.shimmer__wrapper}>
                        <div className={styles.shimmer}></div>
                    </div>
                </h3>
                <div className={styles.form__block}>
                    <div className={styles.image}>
                        <div className={styles.shimmer__wrapper}>
                            <div className={styles.shimmer}></div>
                        </div>
                    </div>
                </div>

                <p className={styles.block__descr}>
                    <div className={styles.shimmer__wrapper}>
                        <div className={styles.shimmer}></div>
                    </div>
                </p>

                <div className={styles.block__wrapper}>
                    <div className={styles.project__block}>
                        <div className={styles.shimmer__wrapper}>
                            <div className={styles.shimmer}></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`component__block-submit ${styles.bottom}`}></div>
        </div>

    </>

  )
}

export default ProjectSkeleton;