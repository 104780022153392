import styles from './Project.module.scss';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form} from 'formik';
import * as Yup from 'yup';

import AuthService from '../../services/AuthService';
import { projectsStore } from '../../stores/ProjectsStore';

import ProjectBlock from '../../ComponentsUI/ProjectBlock/ProjectBlock';
import DataService from '../../services/DataService';
import bgPic from '../../assets/images/cubist_houses.jpg';
import addPic from '../../assets/images/add.png';
import FormikControl from '../../ComponentsUI/FormikControl/FormikControl';

const  ProjectForm =  ({id, project, setProject}) => {

    const { putData, postData, process, setProcess } = AuthService();
    const { _transformProject, _updateStorage } = DataService();
    const navigate = useNavigate();
    
    useEffect(() => {
        setProcess('confirmed')
    }, [process]);

    let initialValues = {
        name: (process === 'confirmed' && project !== null) ? project.name : '',
        description: (project !== null && process === 'confirmed') ? project.description : '',
        fundraisingDuration: (project && process === 'confirmed') ? project.fundraisingDuration : 4,
        projectDuration: (project !== null && process === 'confirmed') ? project.projectDuration : 0,
        loanAmount: (project !== null && process === 'confirmed') ? project.amount : 0,
        loanRate: (project !== null && process === 'confirmed') ? project.rate : 0
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Введите название проекта')
                          .min(10, 'Название проекта не может быть менее 5 символов'),
        description: Yup.string().required('Введите описание проекта')
                                 .min(20, 'Описание проекта не может быть менее 20 символов')
                                 .max(1000, 'Описание проекта не может быть более 1000 символов'),
        projectDuration: Yup.number().required('Введите срок займа')
                            .min(1, 'Срок займа не может быть меньше одного месяца')
                            .max(36, 'Срок займа не может быть больше 36 месяцев'),
        loanAmount: Yup.number().required('Введите сумму займа'),
        loanRate: Yup.number().required('Введите процент займа'),
    });

    const handleSubmit = async (values) => {

        const projectInfo = {
            name: values.name,
            description: values.description,
            fundraisingDuration: values.fundraisingDuration,
            projectDuration: values.projectDuration,
            loanAmount: values.loanAmount,
            loanRate: values.loanRate
        }

        const token = localStorage.getItem("token");
        const storedUser = JSON.parse(localStorage.getItem("user"));

        console.log(projectInfo)
        if (token && storedUser) {
            let request= (project && project.currentStatus) ? putData(`project/${id}`, projectInfo) : postData("project", projectInfo)
                request.then((response) => {
                    console.log(response)
                    let projectData = _transformProject(response.data.project)
                        projectsStore.setProjects(projectData)
                        navigate(`/project/loan/${projectData.id}/info`);
                        _updateStorage(projectData, response.data.project.id, "projects")
                })
                .then(() => setProcess('confirmed'))
        }
    }

    return (
        <Formik 
            initialValues={initialValues} 
            validationSchema={validationSchema} 
            enableReinitialize
            onSubmit= {async (values, { resetForm }) => {
                 const resp = await handleSubmit(values)
                  if (resp) {
                     resetForm()
                  }
               }}
        >   
            {({values, setValues}) => {
                return (
                    <Form action="post">
                        <div className="component__union">
                            <div className="component__union-type">
                                <h5 className="component__union-title">Общие данные</h5>
                                <p className="component__union-descr">Основная информация о проекте</p>
                            </div>
                            <div className={styles.sub__union}>
                                <div className={`${styles.block} component__block`}>
                                    <div className={`component__block-main ${styles.block__main}`}>
                                        <div className={styles.block__wrapper}>
                                            <div className={styles.form__block}>
                                                <div
                                                style={{backgroundImage: (project && project.id) ? `url(${bgPic})` : `url(${addPic})`}} 
                                                className={(project && project.id) ? styles.image : (styles.image + ' ' + styles.image__add)}
                                                    
                                                >
                                                </div>
                                                <button className="component__btn component__btn-small">Выбрать новое фото</button>
                                            </div>
                                            <div className={styles.form__block}>
                                                <p className="component__subt">Наименование проекта</p>
                                                <div className={styles.block__wrapper}>
                                                    <FormikControl 
                                                        control="input"
                                                        name="name" 
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                            
                                            <div className={styles.form__block}>
                                                <p className="component__subt">длительность СБОРА - 
                                                    <span> {values.fundraisingDuration} недель</span>
                                                </p>
                                                <FormikControl
                                                    control="range" 
                                                    min={1}
                                                    max={12}
                                                    step={1}
                                                    name="fundraisingDuration" 
                                                />
                                            </div>

                                            {<div className={styles.form__block}>
                                                <p className="component__subt">аннотация</p>
                                                <div className={styles.block__wrapper}>
                                                    <FormikControl
                                                        control="textarea"
                                                        name='description'
                                                    />
                                                </div>
                                            </div>}
                                            
                                        </div>

                                        <div className={styles.form__block + " " + styles.btn__block}>
                                                <button className="component__btn component__btn-small">Подключить данные из личного кабинта</button>
                                                <button className="component__btn component__btn-small">Загрузить сканы документов</button>
                                        </div>

                                </div>

                                </div>

                                <div className={`${styles.block} component__block`}>
                                    <div className={`${styles.block__main} component__block-main`}>
                                        <h6 className="component__block-title">Рассчёт условий займа </h6>
                                        <div>
                                            <div className={styles.form__block}>
                                                <p className="component__subt">Сумма займа</p>
                                                <div className={styles.block__wrapper}>
                                                    <FormikControl 
                                                        control="input"
                                                        type="number" 
                                                        name="loanAmount"
                                                    />
                                                </div>
                                            </div>
                        
                                            <div className={styles.form__block}>
                                                <p className="component__subt">срок займа <span>(В ПЕРИОД от 1 до 36 месяцев)</span></p>
                                                <div className={styles.block__wrapper}>
                                                    <FormikControl 
                                                        control="input"
                                                        type="number" 
                                                        name="projectDuration"
                                                    />
                                                </div>
                                            </div>

                                            <div className={styles.form__block}>
                                                <p className="component__subt">Выплачиваемый процент</p>
                                                <div className={styles.block__wrapper}>
                                                    <FormikControl 
                                                        control="input" 
                                                        type="number" 
                                                        name="loanRate"
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                        <div className={styles.block__wrapper}>
                                            <ProjectBlock sum={values.loanAmount} percent={values.loanRate}/>
                                        </div>
                                    </div>

                                    {(process === 'confirmed' && (!project || (project && 
                                                                  (project.currentStatus === 'STATUS_DRAFT' 
                                                                || project.currentStatus === 'STATUS_ON_MODERATE' 
                                                                || project.currentStatus === 'STATUS_MODERATED' 
                                                                || project.currentStatus === 'STATUS_REJECTED')))) && 
                                    (<div className={`component__block-submit ${styles.submit__block}`}>
                                        { !project && (<button type="submit" className="component__btn component__btn-small">Создать проект</button>)}
                                        {project && (project.currentStatus === 'STATUS_DRAFT' 
                                                  || project.currentStatus === 'STATUS_ON_MODERATE' 
                                                  || project.currentStatus === 'STATUS_MODERATED' 
                                                  || project.currentStatus === 'STATUS_REJECTED') && 
                                            (<>
                                                <button type="submit" className="component__btn component__btn-small">Сохранить</button>
                                                <Link to={`/project/loan/${id}/info`}>
                                                    <button 
                                                        onClick={() => setValues(initialValues)}
                                                        type="button" 
                                                        className="component__btn component__btn-small component__btn-cancel">
                                                        Отменить редактирование
                                                    </button>
                                                </Link>
                                            </>
                                            )}
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </Form>
                )
            }

        }
        </Formik>
    )
}

export default ProjectForm;