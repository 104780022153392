import styles from './Skeleton.module.scss';

const CardsSkeleton = ({blockHeight}) => {

  const heightStyle = {height: `${blockHeight}px`}
  return (
    <>
      <div style={heightStyle} className={styles.skeleton__card}>
        <div className={styles.shimmer__wrapper}>
          <div className={styles.shimmer}></div>
        </div>
      </div>

    </>

  )
}

export default CardsSkeleton;