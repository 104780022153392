import axios from 'axios';

// https://www.bezkoder.com/react-refresh-token/#:~:text=React%20Refresh%20Token%20with%20JWT%20overview,-The%20diagram%20shows&text=%E2%80%93%20A%20legal%20JWT%20must%20be,it%20for%20new%20resource%20request.
const instance = axios.create({
    baseURL: `https://api.oip.sindev.ru/v1/`,
    headers: {
        "Content-Type": "application/json",
    },
});


let token = localStorage.getItem("token");

instance.interceptors.request.use(
    (config) => {
        token = localStorage.getItem("token");
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

if (token) {
    instance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;

            if (originalConfig.url.indexOf('/auth/') === -1 && err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;

                    try {
                        const refreshToken = localStorage.getItem("refreshToken");

                        const response = await instance.post("/auth/refresh-token", {
                            "refreshToken": refreshToken,
                        });

                        const {token} = response.data.token;
                        // const {refreshToken} = response.data.refreshToken;


                        localStorage.setItem("token", response.data.token);
                        localStorage.setItem("refreshToken", response.data.refreshToken);

                        return instance(originalConfig);
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                }
            }


            return Promise.reject(err);
        }
    );
}

export default instance;