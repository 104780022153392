import { Outlet } from "react-router-dom";

import DashboardTop from "../../ComponentsUI/DashboardTop/DashboardInfo/DashboardInfo";


const Project = () => {

    return (
        <>
            <DashboardTop/>
            <Outlet/>
        </>
    )
}

export default Project;