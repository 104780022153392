import InputMask from 'react-input-mask';
import { Field, ErrorMessage } from 'formik';
import TextError from '../TextError/TextError';
import styles from './InpMask.module.scss';

const InpMask = (props) => {
  const { name, type } = props;

    return (
        <>
           <Field name={name}>
             {({ field: {value, ...field}, form: {setFieldValue, initialValues}, meta }) => {
              const setInpStyle = (styles) => {
                  let inpStyle = `${styles.inp} `;
                  if (meta.touched && meta.error) {
                      inpStyle += `${styles.inp__err}`;
                  } else if (value) {
                      inpStyle += `${styles.inp__success}`;
                  }
                  return inpStyle
              }

              return (
                <>
                  <InputMask 
                    className={setInpStyle(styles)}
                    type={type}
                    as={type} 
                    id={name} 
                    name={name} 
                    mask="+7 (999) 999-99-99"
                    alwaysShowMask={value === '' ? true : false}
                    value={value}
                    onChange={(e) => {
                        let value = e.target.value || '';
                        setFieldValue(name, value);
                    }}
                    {...field}
                  />
                {meta.touched && meta.error && (
                  <ErrorMessage component={TextError} name={name} text={meta.error}/>
                )}
              </>
              )
             }}
           </Field>
        </>
    )
}

export default InpMask ;