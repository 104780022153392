import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import AuthService from '../../services/AuthService';

import FormikControl from '../../ComponentsUI/FormikControl/FormikControl';
import Modal from '../../ComponentsUI/Modal/Modal';
import styles from './Settings.module.scss';


const SecuritySettings = () => {
    const [showModal, setShowModal] = useState(false)
    const storedData = JSON.parse(localStorage.getItem("user"));
    const { postData, loading, error, process, setProcess } = AuthService();

    const initialValues = {
        currentPassword: '',
        password: '',
        passwordConfirm: ''
    }
    const validationSchema = Yup.object().shape({
        currentPassword: Yup.string().required('Введите текущий пароль'),
        password: Yup.string().required('Введите новый пароль').min(4, 'Длина пароля должна быть не менее 4 знаков').required('Введите новый пароль'),
        passwordConfirm: Yup.string().required('Подтвердите пароль').oneOf([Yup.ref("password"), null], "Пароли не совпадают")
    })

    const handleSubmit = (values) => {

        const passwordData = {
            currentPassword: values.currentPassword,
            password: values.password,
        }

        postData("/profile/change-password", passwordData)
            .then(() => setProcess('confirmed'))
            .then ( response => {
                setShowModal(true)
            })

    }

    return (
        <>
        <Modal handleClose={() => setShowModal(false)} isOpen={showModal} >
            {process === 'confirmed' 
                &&  (<>
                        <h3>Изменения успешно сохранены</h3>
                        <p>Можете вернуться в профиль</p>
                        <div>
                            <Link to={`main/user/${storedData.profile.businessRole}`}>
                                <button 
                                    onClick={() => setShowModal(false)}
                                    className="component__btn component__btn-big component__btn-submit"
                                >
                                    На главную</button>
                            </Link>
                        </div>
                    </>
                        )}
            {process === `error` 
                &&  (<>
                        <h3>Возникла ... ошибка</h3>
                        <p>Попробуйте изменить данные ещё раз</p>
                        <div>
                            <button 
                                onClick={() => setShowModal(false)}
                                className="component__btn component__btn-big component__btn-cancel"
                            >
                                Попробовать снова</button>
                        </div>
                    </>)
            }
        </Modal>
            <div className={`wrapper ${styles.settings__wrapper}`}>
                <div className="component__union">
                    <div className="component__union-type">
                        <h5 className="component__union-title">Обновить пароль</h5>
                        <p className="component__union-descr">Убедитесь, что в вы придумали надёжный пароль. Если вы затрудняетесь - воспользуйтесь генератором паролей.</p>
                    </div>

                <Formik 
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit = {async (values, { resetForm }) => {
                        const resp = await handleSubmit(values)
                        if (resp) {
                            resetForm()
                        }
                    }}
                >   
                    {() => {

                        return (
                                <Form action="post" className={`${styles.block} component__block`}>
                                    <div className={`${styles.block__main} component__block-main`}>
                                        <div className={styles.block__wrapper}>
                                            <div className={styles.form__block}>
                                                <p className="component__subt">текущий пароль</p>
                                                <div className={styles.block__wrapper}>
                                                    <FormikControl
                                                        control="input"
                                                        type="password" 
                                                        name="currentPassword" 
                                                    />
                                                </div>
                                            </div>

                                            <div className={styles.form__block}>
                                                <p className="component__subt">новый пароль</p>
                                                <div className={styles.block__wrapper}>
                                                    <FormikControl
                                                        control="input"
                                                        type="password" 
                                                        name="password" 
                                                    />
                                                </div>
                                            </div>

                                            <div className={styles.form__block}>
                                                <p className="component__subt">подтвердить пароль</p>
                                                <div className={styles.block__wrapper}>
                                                    <FormikControl
                                                        control="input"
                                                        type="password" 
                                                        name="passwordConfirm"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="component__block-submit">
                                        <button type='submit' className="component__btn component__btn-small">Сохранить</button>
                                    </div>
                                </Form>
                    )}}
                </Formik>
                </div>
            <div className="component__union">
                <div className="component__union-type">
                    <h5 className="component__union-title">Двухфакторная аутентификация</h5>
                    <p className="component__union-descr">
                                Добавьте дополнительную безопасность к своей учетной записи, 
                                используя двухфакторную аутентификацию.
                    </p>
                </div>

                <div className={`${styles.block} component__block`}>
                <div className={`${styles.block__main} component__block-main`}>
                    <h6 className="component__block-title">Вы не включили двухфакторную аутентификацию.</h6>
                    <p className={styles.block__descr}>
                                    Когда включена двухфакторная аутентификация, вам будет предложено ввести безопасный случайный код во время аутентификации. 
                                    Вы можете получить этот код из приложения Google Authenticator на вашем телефоне.
                    </p>

                </div>
                <div className="component__block-submit">
                    <button className="component__btn component__btn-small">Включить</button>
                </div>
                </div>
            </div>

            <div className="component__union">
                <div className="component__union-type">
                    <h5 className="component__union-title">Сессия</h5>
                    <p className="component__union-descr">
                                    Управляйте своими активными сеансами и выходите из них в других браузерах и устройствах.
                    </p>
                </div>

                <div className={`${styles.block} component__block`}>
                <div className={`${styles.block__main} component__block-main`}>
                    <p className={styles.block__descr}>
                                    При необходимости вы можете выйти из всех других сеансов вашего браузера на всех ваших устройствах. 
                                    Некоторые из ваших недавних сеансов перечислены ниже; однако этот список может быть неполным. 
                                    Если вы считаете, что ваша учетная запись была скомпрометирована, вам также следует обновить свой пароль.
                    </p>

                </div>
                <div className="component__block-submit">
                    <button className="component__btn component__btn-small">Выйдите из других сеансов Браузера</button>
                </div>
                </div>
            </div>
        </div>                                                   
        </>
    )
}

export default SecuritySettings;