import { useState } from 'react';
import styles from './PaginationItem.module.scss';


const PaginationItem = ({transaction}) => {
    const [isOpened, setIsopened] = useState(false);

    function returnDate(date) {
        if (date) {
            date = new Date(date)
            var dd = date.getDate();
            if (dd < 10) dd = '0' + dd;
          
            var mm = date.getMonth() + 1;
            if (mm < 10) mm = '0' + mm;
          
            var yy = date.getFullYear();
            if (yy < 10) yy = '0' + yy;
          
            return dd + ' ' + mm + ' ' + yy;
        } else return null
        
    }

    return (
        <>
            <div className={styles.info}>
                <div className={isOpened ? styles.row + ' ' + styles.row__active : styles.row}>
                    <div className={styles.left}>
                        <div className={styles.row__column + ' ' + styles.id}>
                            <p>
                                {transaction.id}
                            </p>
                        </div>
                        <div className={styles.row__column}>{returnDate(transaction.date)}</div>
                        <div className={styles.row__column}>{transaction.amountDst}</div>
                        <div className={styles.row__column}>{transaction.commission !== 0 ? transaction.commission : '-'}</div>

                    </div>

                    <div className={styles.right}>
                        <div className={styles.row__column}>
                            <div className={styles.column__type}>Описание операции</div> 
                            <div className={styles.column__info}>{transaction.descr}</div>
                        </div>
                        <div className={styles.row__column}>
                            <div className={styles.column__type}>Дополнительно</div>
                            <div className={styles.column__info}>На кофе</div>
                        </div>
                    </div>
                    <div 
                            className={styles.menu}
                            onClick={() => setIsopened(!isOpened)}
                        >
                            <span className={styles.menu__line}></span>
                            <span className={styles.menu__line}></span>
                            <span className={styles.menu__line}></span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaginationItem;