
import { Outlet } from "react-router-dom";
import styles from './MainLayout.module.scss';
import logo from '../../assets/images/big_logo.png';
import Loader from "../../ComponentsUI/Loader/Loader";
const MainLayout = (isLoggedIn, setIsLoggedIn) => {

    return (
        <>
            <main className={`main ${styles.main}`}>
                <div className="wrapper wrapper__form">

                    <Outlet 
                    // context={[isLoggedIn, setIsLoggedIn]}
                    />
                    <img src={logo} alt="Logo" className={styles.logo}/>
                </div>
            </main>
        </>
    );
}

export default MainLayout;