import { useEffect } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';

import { projectsStore } from '../../../stores/ProjectsStore';
import { published, unpublished } from "../../../variables/projectDuration";

import DataService from "../../../services/DataService";
import AuthService from '../../../services/AuthService';

import ProfilePanel from "../../../ComponentsUI/Panels/ProfilePanel/ProfilePanel";

import DashboardInfo from '../../../ComponentsUI/DashboardTop/DashboardInfo/DashboardInfo';
import CardsSceleton from "../../../ComponentsUI/Skeleton/CardsSkeleton";

import styles from './Profile.module.scss';
import ProjectCard from "../../../ComponentsUI/ProjectCard/ProjectCard";
import AccountPanel from "../../../ComponentsUI/Panels/AccountPanel/AccountPanel";

const ProfileBorrower = observer(function ProfileBorrower () {

    const { _transformProject } = DataService();
    const {getData, process, setProcess} = AuthService();


    useEffect(() => {
        setProcess('loading')
        getProjects()
    }, []);

    const getProjects = async () => {
        const response = getData('project')
                .then(response => {
                    console.log(response)
                    if (response.data.project.length) {
                        onProjectLoaded(response.data.project)
                    } else {
                        projectsStore.setProjects([])
                    }
                })
                .then(() => setProcess('confirmed')) 
    }

    const onProjectLoaded = (data) => {
        let projects = data.map(_transformProject)
        projectsStore.setProjects(projects)
        console.log(projectsStore.projects)
        localStorage.setItem("projects", JSON.stringify(projects))
        window.scrollTo(0,0)
    }

    const renderProjects = (arr, type) => {
        let projects = arr.filter(i => type.includes(i.currentStatus))
                            .map((item) => {
                                return (
                                    <ProjectCard item={item} type={type} key={item.id}/>
                                )
                            });
        return (
            <>
                    {projects}
            </>   
        )
    }

  return (

    <>
        <DashboardInfo/>
        <div>
            <ProfilePanel process={process}/>

            <div className={styles.projects}>
                <div className={`wrapper ${styles.projects__wrapper}`}>
                    <div className={styles.projects__unpublished}>

                        <div className={styles.projects__top}>
                                <div className={styles.projects__left}>
                                    <h2 className={styles.projects__title}>Неопубликованные проекты</h2>
                                    <p className={styles.projects__subt}>Список проектов, которые находятся на проверке, или требуют вашего внимания</p>
                                </div>

                                {(process === 'confirmed' && projectsStore.projects.filter(item => unpublished.includes(item.currentStatus)).length !== 0)
                        && (<Link to="/project/loan/new" className={styles.projects__btn}>
                                    Создать проект
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M26.25 15H3.75" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M15 26.25L15 3.75" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </Link>)}
                        </div>

                        <div className={styles.cards}>
                            {process === 'loading' && ([...new Array(6)].map((_, i) => <CardsSceleton blockHeight={120} key={i}/>))}
                            {(process === 'confirmed' && projectsStore.projects.length !== 0) && renderProjects(projectsStore.projects, unpublished)}
                        </div>
                    </div>
                    
                    <div className='projects__published'>
                        <div className={styles.projects__top}>
                            <div className={styles.projects__left}>
                                <h2 className={styles.projects__title}>Текущие проекты</h2>
                                <p className={styles.projects__subt}>Список подключенных у вас проектов</p>
                            </div>

                            {(process === 'confirmed' && projectsStore.projects.filter(item => unpublished.includes(item.currentStatus)).length === 0)
                            && (<Link to="/project/loan/new" className={styles.projects__btn}>
                                    Создать проект
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M26.25 15H3.75" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M15 26.25L15 3.75" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </Link>)}
                        </div>
                        
                        {(process === 'confirmed' && projectsStore.projects.filter(item => item.currentStatus === 'STATUS_READY_TO_START'
                                                                         || item.currentStatus === 'STATUS_STARTED' 
                                                                         || item.currentStatus === 'STATUS_WORK').length === 0) 
                                                &&  (<div className={styles.empty}>
                                                    <h4 className={styles.empty__title}>Пока что тут пусто</h4>
                                                    <p className={styles.empty__descr}>Хотите осуществить давно задуманное? Создайте свой первый проект.</p>
                                                </div>)}

                        <div className={styles.cards}>
                            {process === 'loading' && ([...new Array(6)].map((_, i) => <CardsSceleton blockHeight={120} key={i}/>))}
                            {(process === 'confirmed' && projectsStore.projects.length !== 0) && renderProjects(projectsStore.projects, published)}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </>

  )
})

export default ProfileBorrower