import { Outlet } from "react-router-dom";

const Profile = (props) => {
    return (
        <> 
            <Outlet/>
        </>
    )
}

export default Profile;
