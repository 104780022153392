import { Field, ErrorMessage } from 'formik';
import TextError from '../TextError/TextError';

import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";

import styles from './DatePicker.module.scss';
import './DatePicker.scss';
import "react-datepicker/dist/react-datepicker.css";

const DatePickerField = (props) => {
    const { name, type } = props;
    const today = new Date()
    let tomorrow =  new Date()
    tomorrow.setDate(today.getDate() + 1)
    registerLocale("ru", ru);

    const formatUTC = (dateInt, addOffset = false) => {
        let date = (!dateInt || dateInt.length < 1) ? null : new Date(dateInt);
        if (date) {
            if (typeof dateInt === "string") {
                return date;
            } else {
                const offset = addOffset ? date.getTimezoneOffset() : -(date.getTimezoneOffset());
                const offsetDate = new Date();
                offsetDate.setTime(date.getTime() + offset * 60000)
                return offsetDate;
            }
        }
    }

    return (
        <Field name={name}>
             {({ field: {value, onChange, ...field}, form: {initialValues, setFieldValue}, meta }) => {
              const setInpStyle = (styles) => {
                  let inpStyle = `${styles.inp} `;
                  if (meta.touched && meta.error) {
                      inpStyle += `${styles.inp__err}`;
                  } else if (value) {
                      inpStyle += `${styles.inp__success}`;
                  }
                  return inpStyle
              }
              return (
                <>
                    <DatePicker
                        className={setInpStyle(styles)}
                        type={type}
                        dateFormat="dd/MM/yyyy"
                        value={value ? formatUTC(value) : initialValues[name]}
                        timeFormat="HH:mm"
                        minDate={today}
                        selected={(formatUTC(value))}
                        onChange={val => {
                            setFieldValue(name, val);
                        }}
                        locale="ru"
                        {...field}
                    />
                    {meta.touched && meta.error && (
                    <ErrorMessage component={TextError} name={name} text={meta.error}/>
                  )}
                </>
              )
            }}
          </Field>

    );
};

export default DatePickerField;