import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DashboardInfo from "../../ComponentsUI/DashboardTop/DashboardInfo/DashboardInfo";
import InnerNav from "../../ComponentsUI/InnerNav/InnerNav";

const Settings = () => {

    let navigate = useNavigate();

    useEffect(() => {
        return navigate("profile");
    }, []);

    const data = [
                    {path: 'profile', title: 'Профиль'},
                    {path: 'security', title: 'Безопасность'},
                    {path: 'requisites', title: 'Реквизиты'}
                ]
    return (
        <>
            <DashboardInfo/>
            <InnerNav data={data}/>
            <Outlet/>
        </>
    )
}

export default Settings;