import { useState} from 'react';
import { Link } from 'react-router-dom';

import DataService from '../../services/DataService';
import AuthService from '../../services/AuthService';
import { userStore } from '../../stores/UserStore';
import Modal from '../../ComponentsUI/Modal/Modal';
import * as Yup from 'yup';
import { Formik, Form} from 'formik';

import FormikControl from '../../ComponentsUI/FormikControl/FormikControl';
import styles from './Settings.module.scss';

const SettingsProfile = () => {

    const storedData = JSON.parse(localStorage.getItem("user"));
    const phoneRegExp = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;

    const [showModal, setShowModal] = useState(false);
    
    const { _transformProfile, _updateProfileStorage } = DataService()
    
    const {postData, process, setProcess} = AuthService();

    const showRole = () => {
        if (!storedData.profile.businessRole) return;
        let role = "";
        if (storedData.profile.businessRole === 'borrower') {
            role = "Я заёмщик"
        } else if (storedData.profile.businessRole === 'investor') {
            role = "Я инвестор"
        } 
        return role;
    }

    const rolesObj = [{
            val: "borrower",
            label: "Я заёмщик"
        },
        {
            val: "investor",
            label: "Я инвестор"
        }
    ]

    const handleSubmit = async (values) => {

        const userInfo = {
            fio : {
                firstName: values.firstName,
                lastName: values.lastName,
                middleName: values.middleName
            },
            businessRole: values.businessRole,
            phone: values.phone
        }

        const token = localStorage.getItem("token");
        if (token) {

            postData("profile", userInfo)
                .then(response => {
                    let profileData = _transformProfile(response.data.profile);
                    _updateProfileStorage(profileData.profile)
                    console.log(JSON.parse(localStorage.getItem('user')))
                    userStore.setUserData(JSON.parse(localStorage.getItem('user')))
                    setShowModal(true)
                })
                .then(() => setProcess('confirmed'))
        }
    }

    const initialValues = {
        firstName: storedData.profile.firstName ? storedData.profile.firstName : '',
        lastName: storedData.profile.lastName ? storedData.profile.lastName : '',
        middleName: storedData.profile.middleName ? storedData.profile.middleName : '',
        businessRole: storedData.profile.businessRole ? storedData.profile.businessRole : 'borrower',
        phone: storedData.profile.phone ? storedData.profile.phone : ''
    }

    console.log(storedData.phone)
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('Введите имя'),
        lastName: Yup.string().required('Введите фамилию'),
        middleName: Yup.string().required('Введите отчество'),
        phone: Yup.string().required('введите номер телефона').matches(phoneRegExp, 'Неверный номер')
    })

    return (
    <>
        <Modal handleClose={() => setShowModal(false)} isOpen={showModal} >
        {process === 'confirmed'  
            && (<>
                    <h3>Изменения успешно сохранены</h3>
                    <p>Можете вернуться в профиль</p>
                    <div>
                        <Link to={`main/user/${storedData.profile.businessRole}`}>
                            <button 
                                onClick={() => setShowModal(false)}
                                className="component__btn component__btn-big component__btn-submit"
                            >
                                На главную</button>
                        </Link>
                    </div>
                </>
            )}
            {process === 'error' 
                && (<>
                        <h3>Возникла ... ошибка</h3>
                        <p>Попробуйте изменить данные ещё раз</p>
                        <div>
                            <button 
                                onClick={() => setShowModal(false)}
                                className="component__btn component__btn-big component__btn-cancel"
                            >
                                Попробовать снова</button>
                        </div>
                    </>)}
        </Modal>
        <div className={styles.details}>
            <div className={`wrapper ${styles.settings__wrapper}`}>
                <div className="component__union">
                    <div className="component__union-type">
                        <h5 className="component__union-title">Информация о вас</h5>
                        <p className="component__union-descr">Обновите информацию профиля вашей учетной записи и адрес электронной почты.</p>
                    </div>
                    <Formik
                        initialValues = {initialValues}
                        validationSchema = {validationSchema}
                        enableReinitialize
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                              handleSubmit(values)
                              setSubmitting(false);
                            }, 400);
                          }}
                          
                    >
                        {() => {
                            
                            return (
                                <Form action='post' className={`${styles.block} component__block`} >
                                    <div className={`${styles.block__main} component__block-main`}>
                                        <div className={styles.block__img}>Tt</div>
                                            <button type='button' className="component__btn component__btn-small">Выбрать новое фото</button>
                                            <div className={styles.radio}>
                                                    {((!storedData && !storedData.profile.businessRole) || (storedData && !storedData.profile.businessRole))
                                                    ?   (<>
                                                            <FormikControl 
                                                                control="radio" 
                                                                name="businessRole" 
                                                                data={rolesObj}
                                                            />
                                                        </>)
                                                    :   ( <>
                                                            <div className={`${styles.form__legend} component__legend`}>
                                                                {showRole()}
                                                            </div>
                                                        </>)                        
                                                }
                                            </div>
                                        
                                        <div>
                                            <div className={styles.form__block}>
                                                <p className="component__subt">ИМЯ</p>
                                                <div className={styles.block__wrapper}>
                                                    <FormikControl
                                                        control="input"
                                                        type="text" 
                                                        name="firstName" 
                                                    />
                                                </div>
                                            </div>
                        
                                            <div className={styles.form__block}>
                                                <p className="component__subt">ФАМИЛИЯ</p>
                                                <div className={styles.block__wrapper}>
                                                    <FormikControl
                                                        control="input"
                                                        type="text" 
                                                        name="lastName"
                                                    />
                                                </div>
                                            </div>

                                            <div className={styles.form__block}>
                                                <p className="component__subt">ОТЧЕСТВО</p>
                                                <div className={styles.block__wrapper}>
                                                    <FormikControl
                                                        control="input"
                                                        type="text" 
                                                        name="middleName" 
                                                    />
                                                </div>
                                            </div>
            
                                            <div className={styles.form__block}>
                                                <p className="component__subt">ТЕЛЕФОН</p>
                                                <div className={styles.block__wrapper}>
                                                    <FormikControl 
                                                        control="mask" 
                                                        mask="+7 (999) 999-99-99" 
                                                        name="phone" 
                                                        type="tel"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    <button type="button" className="component__btn component__btn-small">Загрузить сканы документов</button>
                                    </div>
            
                                    <div className="component__block-submit">
                                        <button type="submit" className="component__btn component__btn-small">Сохранить</button>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>

                </div>


                {/* <div className="component__union">
                    <div className="component__union-type">
                        <h5 className="component__union-title">Оповещения</h5>
                        <p className="component__union-descr">Решите, какие сообщения вы хотели бы получать и как.</p>
                    </div>

                    <div className={`${styles.block} component__block`}>
                    <div className={`${styles.block__main} component__block-main`}>
                        <h6 className="component__block-title">По электронной почте</h6>

                        <div className={styles.form__block}>
                            <input type="checkbox" className="component__checkbox" id="recomendation" name="recomendation"/>
                            <label for="recomendation" className="component__label">
                                <div className="component__label-block"></div>
                                <legend className="component__legend">Рекомендации 
                                    <span>Получать информацию об инвестициях подходящих под ваш профиль.</span>
                                </legend>
                            </label>
                        </div>

                        <div className={styles.form__block}>
                            <input type="checkbox" className="component__checkbox" id="news" name="news"/>
                            <label for="news" className="component__label">
                                <div className="component__label-block"></div>
                                <legend className="component__legend">Новости
                                    <span>Получать информация о новинках платформы.</span>
                                </legend>
                            </label>
                        </div>

                    </div>
                    <div className="component__block-submit">
                        <button className="component__btn component__btn-small">Сохранить</button>
                    </div>
                    </div>
                </div> */}

                <div className="component__union">
                    <div className="component__union-type">
                        <h5 className="component__union-title">Удалить учётную запись</h5>
                        <p className="component__union-descr">Навсегда удалить ваши персональные данные.</p>
                    </div>

                    <div className={`${styles.block} component__block`}>
                        <div className={`${styles.block__main} component__block-main`}>
                            <h6 className="component__block-title">Удалить учётную запись</h6>

                            <p className={styles.block__descr}>
                                Как только ваша учетная запись будет удалена, 
                                все ее ресурсы и данные будут удалены безвозвратно. Перед удалением вашей учетной записи, 
                                пожалуйста, загрузите любые данные или информацию, которые вы хотите сохранить.
                            </p>
                        </div>

                        <div className="component__block-delete">
                            <button className="component__btn component__btn-small">Удалить учётную запись</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default SettingsProfile