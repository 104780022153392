import MainSelect from "../MainSelect/MainSelect";
import AccountsSelect from "../AccountsSelect/AccountsSelect";
import TextArea from "../TextArea/TextArea";
import Range from "../Range/Range";
import Checkbox from "../Checkbox/Checkbox";
import Input from "../Input/Input";
import Radio from "../Radio/Radio";
import InpMask from "../InpMask/InpMask";

const FormikControl = (props) => {
    const { control, ...rest } = props
      switch (control) {
        case 'input': {
          return <Input {...rest} />
        }
        case 'textarea': {
          return <TextArea {...rest} />;
        }
        case 'select': {
          return <MainSelect {...rest} />;
        }
        case 'accounts-select': {
          return <AccountsSelect {...rest} />;
        } 
        case 'range': {
          return <Range {...rest} />;
        } 
        case 'radio': {
          return <Radio {...rest}/>
        }
        case 'checkbox': {
          return <Checkbox {...rest}/>
        }
        case 'mask': {
          return <InpMask {...rest}/>
        }
        case 'date':
        default:
          return null;
      }
}

export default FormikControl;