import { useState, useLayoutEffect } from "react";
import { createPortal } from "react-dom";

const ReactPortal = ({children, wrapperId}) => {

    function createAndAppend (wrapperId) {
        const wrapperElem = document.createElement('div');
        wrapperElem.setAttribute('id', wrapperId);
        document.body.appendChild(wrapperElem);
        return wrapperElem
    }

    const [wrapperElem, setWrapperElem] = useState(null);

    useLayoutEffect(() => {
        let elem = document.getElementById(wrapperId);
        let systemCreated = false
        if (!elem) {
            systemCreated = true
            elem = createAndAppend(wrapperId)
        }

        setWrapperElem(elem)
        // console.log(wrapperElem)

        return () => {
            if (systemCreated && elem.parentNode) {
                elem.parentNode.removeChild(elem);
            }
        }
    }, [wrapperId])

    
    if (wrapperElem === null) return null;

    return createPortal(children, wrapperElem)
}

export default ReactPortal;