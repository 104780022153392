
import {projectStatuses} from '../variables/projectDuration';
import {accountStatuses} from '../variables/accountStatuses';

const DataService = () => {

    const defineVariableArr = (referenceObj, arr) => {

        return arr.map(item => {
            if (typeof(item.status) === 'number') {
                return {
                    status: referenceObj[item.status],
                    createdAt: item.createdAt
                }
            }
        }) 
    }

    const defineVariable = (referenceObj, variable) => {
            return referenceObj[variable]
    } 

    const _transformProject = (data) => {
        return {
            id: data.id,
            accountId: data.accountId,
            name: data.name,
            description: data.description,
            fundraisingDuration: data.fundraisingDuration,
            projectDuration: data.projectDuration,
            amount: data.loanSettings.amount,
            rate: data.loanSettings.rate,
            statuses: defineVariableArr(projectStatuses, data.statuses),
            currentStatus: defineVariable(projectStatuses, data.statuses[data.statuses.length - 1].status),
            dateStart: data.dateStart
        }
    }

    const _transformAccounts = (data) => {
        return {
            id: data.id,
            name: data.name,
            currency: data.currency,
            type: defineVariable(accountStatuses, data.type),
            amount: data.amount,
            opened: data.statuses[0].createdAt,
            statuses: defineVariableArr(accountStatuses, data.statuses),
            currentStatus: data.statuses[data.statuses.length - 1].status,
        }
    }

    const _transformUser = (data) => {
        console.log(data)
        return {
            account: {
                id: data.profile.accountId,
                login: data.account.login,
                email: data.account.email
            },
            profile: 
            data.profile.hasOwnProperty('fio') ? 
            {
                businessRole: data.profile.businessRole,
                firstName: data.profile.fio.firstName,
                lastName: data.profile.fio.lastName,
                middleName: data.profile.fio.middleName,
                phone: data.profile.phone.number
            } : {}
        }
    }

    const _transformTransactions = (data) => {
        return { 
            id: data.id,
            descr: data.description,
            commission: data.commission.amount,
            type: data.type,
            amountDst: data.moneyDst.amount,
            date: data.createdAt
        }
    }

    const _transformProfile = (data) => {
        return {
            profile : {
                businessRole: data.businessRole,
                firstName: data.fio.firstName,
                lastName: data.fio.lastName,
                middleName: data.fio.middleName,
                phone: data.phone.number
            }
        }
    }

    const _updateStorage = (data, id, item) => {
        let storedData = JSON.parse(localStorage.getItem(item));
        let changedInd = storedData.findIndex(item => item.id === id);
        changedInd === -1 ? storedData.push(data) : storedData.splice(changedInd, 1, data)
        localStorage.setItem(item, JSON.stringify(storedData))
    }

    const _deleteFromStorage = (id, item) => {
        let storedData = JSON.parse(localStorage.getItem(item));
        let changedInd = storedData.find(item => item.id === id)[0];
        storedData.splice(changedInd, 1)
        localStorage.setItem(item, JSON.stringify(storedData))
    }

    const _updateProfileStorage = (data) => {
        let storedUser = JSON.parse(localStorage.getItem("user"));
        storedUser.profile = data;
        console.log(storedUser)
        localStorage.setItem("user", JSON.stringify(storedUser))
    }

    return {
        _transformProject,
        _transformUser,
        _transformProfile,
        _transformTransactions,
        _transformAccounts,
        _updateStorage,
        _deleteFromStorage,
        _updateProfileStorage
    }
}

export default DataService;