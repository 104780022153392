import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import './styles/index.scss'

import {BrowserRouter as Router} from "react-router-dom";



const root = ReactDOM.createRoot(document.getElementById('root'));

// https://stackoverflow.com/questions/61254372/my-react-component-is-rendering-twice-because-of-strict-mode/61897567#61897567
root.render(
    <Router>
        <App/> 
    </Router>
);
