const findName = (data, id) => {
    return data.length !== 0 ? data.filter(item => item.id === id)[0].name : '';
}

export const chooseTitle = (page, user, data, id) => {
    let title = '';

        if (page.indexOf('/settings') !== -1) {
            title = "Настройки";
        } else if (page.indexOf('/user') !== -1 || page.indexOf('/accounts') !== -1) {
            title = user.account.login;
      
        } else if (page.indexOf('/new') !== -1 || page.indexOf('/id') !== -1) {
            title = "Создать проект";
           
        } else if (page.indexOf('/invest') !== -1) {
            title = "Инвестировать в проект";
        } 
        else if (id && page.indexOf(`${id}`) !== -1) {
            const name = findName(data, id)
            title = name ? name : user.account.login
        }  
        else {
            title = user.account.login;
        }
        return title
}

export const chooseType = (data) => {
    let type = '';
    if (data.profile.businessRole === 'borrower') {
        type = 'loan'
    } else if (data.profile.businessRole === 'investor') {
        type = 'investment'
    }

    return type
}