import { useEffect } from 'react';

import ReactPortal from '../ReactPortal/ReactPortal';

import styles from './Modal.module.scss';
import closeBtn from '../../assets/images/close.svg';
const Modal = ({isOpen, handleClose, children}) => {

    if (!isOpen) return null;
    // useEffect(() => {
    //     const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
    //     document.body.addEventListener("keydown", closeOnEscapeKey);
    //     return () => {
    //       document.body.removeEventListener("keydown", closeOnEscapeKey);
    //     };
    //   }, [handleClose]);

    return (
        <>
            <ReactPortal wrapperId="react-portal-modal-container">
                {isOpen && (<div className={styles.modal__wrapper}>
                        <div className={styles.modal}>
                            <div className={styles.close} onClick={handleClose}>
                                <img src={closeBtn} alt="close" />
                            </div>
                            <div className={styles.modal__content}>
                                {children}
                            </div>
                        </div>
                    </div>)}
            </ReactPortal>
        </>
    )
}

export default Modal