import React from 'react'
import Error404 from '../Error/Error404'
function ProjectInvestment() {
  return (
    <div className='wrapper'>
            <Error404 />
    </div>
  )
}

export default ProjectInvestment