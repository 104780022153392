import DashboardTitle from '../DashboardTitle/DashboardTitle';
import DashboardCrumbs from '../DashboardCrumbs/DashboardCrumbs';

import styles from './../DashboardTop.module.scss';


const DashboardInfo = () =>  {

    const user = JSON.parse(localStorage.getItem("user"));
    const projects = JSON.parse(localStorage.getItem("projects"));
    const accounts = JSON.parse(localStorage.getItem("accounts"));

    return (
                <>
                    <div className={styles.top}>
                        <div className={`wrapper ${styles.top__wrapper}`}>
                            <DashboardCrumbs user={user} accounts={accounts} projects={projects}/>
                            <DashboardTitle user={user} accounts={accounts} projects={projects}/>
                        </div>
                    </div>
                </>
    )
}

export default DashboardInfo;
