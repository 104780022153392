import FormikControl from '../../../ComponentsUI/FormikControl/FormikControl';
import AccountCard from '../../../ComponentsUI/AccountCard/AccountCard';
import { Formik, Form} from 'formik';
import * as Yup from 'yup';
import styles from './../AccountSettings.module.scss';
import AccountBlock from '../../../ComponentsUI/AccountBlock/AccountBlock';

const SettingsTransfer = () => {

    const dropdownOptions = [
        { label: 'Вспомогаельный (личный) баланс 1.00 р', value: 'Вспомогаельный (личный) баланс 1.00 р' },
        { label: 'Вспомогаельный (личный) баланс 100.00 р', value: 'Вспомогаельный (личный) баланс 100.00 р' },
        { label: 'Вспомогаельный (личный) баланс 1000.00 р', value: 'Вспомогаельный (личный) баланс 1000.00 р' },
    ]
    const account = {
        title: "Мой счет для котиков",
        dateOpen: "3 февраля 2023",
        balance: "1 001.00",
        status: "main"
    };

    const initialValues = {
        selectOption: '',
        loanAmount: 0,
        message: ''
    }

    const validationSchema = Yup.object({
      selectOption: Yup.string().required('Необходимо выбрать способ пополнения'),
      loanAmount: Yup.string().required('Необходимо ввести сумму'),
      message: Yup.number().required('Необходимо ввести сообщение')
    });

    const handleSubmit = (values) => {
        console.log(values.selectOption)
    }

    return (
        <>
            <div className={`component__union`}>
                <div className="component__union-type">
                    <h5 className="component__union-title">Перевод между счетами</h5>
                    <p className="component__union-descr">Описание</p>
                </div>

                <div className={`${styles.block} component__block`}>
                    <div className={`${styles.block__main} component__block-main`}>
                        <h5 className="component__union-title">Введите данные для перевода</h5>
                        
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            enableReinitialize
                            onSubmit= {async (values, { resetForm }) => {
                                const resp = await handleSubmit(values)
                                if (resp) {
                                    resetForm()
                                }
                            }}
                        >
                            {({values}) => {
                                
                                return (
                                    <Form>
                                        <div>
                                        <div className={styles.form__block}>
                                                <p className="component__subt">счёт куда будут отправлены средства</p>
                                                <div className={styles.block__wrapper}>
                                                    <FormikControl
                                                        control = "accounts-select"
                                                        options={dropdownOptions}
                                                        name="selectOption" 
                                                        value={values.selectOption}
                                                        placeholder="Выбрать свой счёт из списка"
                                                    />
                                                </div>
                                                {/* <div className={styles.block__wrapper}>

                                                </div> */}
                                            </div>

                                            <div className={styles.form__block}>
                                                <p className="component__subt">Сумма</p>
                                                <div className={styles.block__wrapper}>
                                                    <AccountCard account={account}/>
                                                </div>
                                            </div>

                                            <div className={styles.form__block}>
                                                <p className="component__subt">Сумма</p>
                                                <div className={styles.block__wrapper}>
                                                    <FormikControl
                                                        control="input"
                                                        type="number" 
                                                        name="loanAmount"
                                                    />
                                                </div>
                                            </div>

                                            <div className={styles.form__block}>
                                                <p className="component__subt">СООБЩЕНИЕ</p>
                                                <div className={styles.block__wrapper}>
                                                    <FormikControl
                                                        control="input"
                                                        type="number" 
                                                        name="message"
                                                    />
                                                </div>
                                            </div>

                                            <div className={styles.form__block}>
                                                <div className={styles.block__wrapper}>
                                                        <AccountBlock/>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>

                    </div>
                    <div className={`component__block-submit ${styles.submit__block}`}>
                            <button 
                                type="submit" 
                                className="component__btn component__btn-small"
                                >
                                    Пополнить
                            </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SettingsTransfer