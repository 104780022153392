import api from '../api/Api';
import { useDataApi } from '../hooks/useDataApi';

const AuthService = () => {
    const {loading, setLoading, request, error, setError, clearError, process, setProcess} = useDataApi();

    const logout = (url) => {
        const refreshToken = localStorage.getItem("refreshToken");
    
        api
            .delete(url, {data: {
                "refreshToken": refreshToken,
            }})
            .then(response => {
                localStorage.removeItem("projects")
                localStorage.clear();
                window.location.href = '/'
            })
            .catch(err => console.log(err));
    }


    const getData = async (url) => {
        clearError()
        const res = await request(api.get(url))
        return res
    }

    const deleteData = async (url) => {
        clearError()
        const res = await request(api.delete(url))
        return res
    }
    
    const postData = async (url, data) => {
        clearError()
        const res = await request(api.post(url,data))
        return res
    }

    const putData = async (url, data) => {
        return await api.put(url, data)
    }

    return {
        loading, 
        setLoading,
        error,
        setError,
        logout,
        getData,
        postData,
        putData,
        deleteData,
        process, 
        setProcess
    }

}

export default AuthService;