import { Field, ErrorMessage } from 'formik';
import TextError from '../TextError/TextError';
import styles from './Radio.module.scss';

const Radio = ({name, data}) => {

  return (
        <>
          <Field name={name}>
             {({
               field: {value, ...field},
               form,
               meta,
             }) => (
               <>
                  {data.map((item, i) => {
                    return (
                        <div key={item.val} className={styles.form__block}>
                          <input 
                              type="radio" 
                              className={styles.checkbox}  
                              id={item.val} 
                              name={name} 
                              value={item.val} 
                              checked={value === item.val} 
                              {...field}
                          />
                          <label htmlFor={item.val} className={styles.label}>
                              <div className={styles.label__block}></div>
                              <legend className={styles.legend}>{item.label}</legend>
                          </label>
                        </div>
                    )
                  })}

                  {meta.touched && meta.error && (
                      <ErrorMessage component={TextError} name={name} text={meta.error}/>
                  )}
               </>
             )}
          </Field>
        </>
  )
}

export default Radio;