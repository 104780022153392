import styles from './Navbar.module.scss';

import { useMatchMedia } from '../../utils/useMatchMedia';

import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { NavLink } from 'react-router-dom';

const activeClassName = ({isActive}) => isActive ? `${styles.nav__item} ${styles.active}` : `${styles.nav__item}`;

const Navbar = ({menuState}) => {
    
    const user = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")) : null;
    let navbarRef = useRef();
    let navTimeline = useRef();
    let {isNarrowScreen} = useMatchMedia();

    useEffect(() => {
      const ctx = gsap.context(() => {

        navTimeline.current && navTimeline.current.progress(0).kill();
        navTimeline.current = gsap.timeline({paused: true});

        if (!isNarrowScreen) {
          gsap.set(navbarRef.current, {opacity: 1, transform: 'translate(0%, 0%)'});
          console.log('mount wild')
        } else {
          gsap.set(navbarRef.current, {opacity: 0, transform: 'translate(-50%, -100%)'});
          console.log('mount narrow')

        }

        navTimeline.current.to(navbarRef.current,
          {opacity: 1, translateY: '50px', duration: 0.3, ease: "power3.in"});
      
      }, navbarRef);

      return () => ctx.revert();
    }, []);
    
    useEffect(() => {
      console.log(isNarrowScreen)
      if (isNarrowScreen) {
        console.log('narrow');
        gsap.set(navbarRef.current, {opacity: 0, transform: 'translate(-50%, -100%)'});
        menuState ? navTimeline.current.play() : navTimeline.current.reverse() 
      } else {
        console.log('wild')
        gsap.set(navbarRef.current, {opacity: 1, transform: 'translate(0%, 0%)'});
        navTimeline.current.paused()
      }
         
    }, [menuState, isNarrowScreen]);

    return (
        <>
                <nav className={`${styles.nav} navigation`} ref={navbarRef}>
                    <NavLink  to={user ? `main/user/${user.profile.businessRole}` : null} className={activeClassName}>Проекты</NavLink>
                    <NavLink  to={user ? `main/accounts` : null} className={activeClassName}>Счета</NavLink>
                </nav>
        </>
    )
}

export default Navbar;