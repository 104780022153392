import { Field, ErrorMessage } from 'formik';
import TextError from '../TextError/TextError';
import Select from 'react-select';


const AccountsSelect = (props) => {
    const { name, options, touched, placeholder} = props;

    const defaultVal = (options, value) => {
       return  options ? options.find(item => item.value === value) : "";
    }

    const custom = {
        control: (provided, state) => ({
            ...provided,
            background: "#FFF",
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '100%',
            padding: '0 15px',
            height: '50px',
            width: '100%',
            borderRadius: 15,
            boxShadow: '#6366F1',
            borderWidth: 2,
            cursor: 'pointer',
            borderColor: "#010203",
            transition: 'all 0.1s ease-in-out',
            "&:hover": {
                borderColor: (state.isFocused || state.selectOption) ? "#010203" : "#EEEDF0",
                placeholder: (provided) => ({
                    ...provided,
                    color: "#010203",
                }),
            },
            "@media only screen and (max-width: 414px)": {
              ...provided["@media only screen and (max-width: 414px)"],
              fontSize: "12px",
              height: "42px",
              padding: '0 12px',
            },
          }),
      
          placeholder: (provided, state) => ({
            ...provided,
            color: "#010203",
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '100%',
            "@media only screen and (max-width: 414px)": {
              ...provided["@media only screen and (max-width: 414px)"],
              fontSize: "12px",
            },
          }),
      
          input: (provided, state) => ({
            ...provided,
            color: touched ? "#314BCE" : "#F5F6FA",
          }),
      
          singleValue: (provided, state) => ({
            ...provided,
            color: "#314BCE",
          }),

          valueContainer: (provided, state) => ({
            ...provided,
            padding: 0
          }),
      
          option: (provided, state) => ({
            ...provided,
            borderBottom : state.isSelected ? "5px solid #5077FF" : "5px solid transparent",
            display: 'block',
            width: 'fit-content',
            fontWeight: "500",
            fontSize: '14px',
            lineHeight: '100%',
            letterSpacing: "0.02em",
            color: "#010203",
            padding: "0px 0px 10px 0px",
            background: 'transparent',
            marginBottom: 15,
            cursor: 'pointer',
            transition: 'all 0.1s ease-in-out',
            "@media only screen and (max-width: 414px)": {
              ...provided["@media only screen and (max-width: 414px)"],
              fontSize: '12px',
              padding: "0px 0px 8px 0px",
              borderBottom : state.isSelected ? "3px solid #5077FF" : "3px solid transparent",
            },
          }),
      
          menu: (provided) => ({
            ...provided,
            width: '100%',
            backgroundColor: "#FFFFFF",
            padding: "25px 20px 10px",
            "@media only screen and (max-width: 414px)": {
              ...provided["@media only screen and (max-width: 414px)"],
              padding: "20px 15px 0px",
            },
          }),
        }

    return (
        <>
          <Field name={name}>
            {({
              field: {value, onChange, ...field},
              form: {setFieldValue},
              meta,
            }) => (
              <>
                <Select
                    id={name}
                    label={name}
                    value={defaultVal(options, value)}
                    onChange={value => setFieldValue(name, value.value)}
                    options={options}
                    styles={custom}
                    placeholder={placeholder}
                    components={{
                        IndicatorSeparator: () => null
                    }}
                    {...field}
                />
                {meta.touched && meta.error && (
                  <ErrorMessage component={TextError} name={name} text={meta.error}/>
                )}
              </>
            )}
          </Field>
        </>
    );
}

export default AccountsSelect;