import * as Yup from 'yup';
import { Formik, Form} from 'formik';
import styles from './../AccountSettings.module.scss';
import FormikControl from '../../../ComponentsUI/FormikControl/FormikControl';
const SettingsParams = () => {

    const initialValues = {
        newName: ''
    }
    const validationSchema = Yup.object({
        newName: Yup.string().required('Введите имя'),
    });

    const handleSubmit = (values) => {
        console.log(values.selectOption)
    }

    return (
        <>
            <div className={`component__union`}>
                <div className="component__union-type">
                    <h5 className="component__union-title">Переименовать счёт</h5>
                    <p className="component__union-descr">Описание</p>
                </div>

                <Formik
                    initialValues = {initialValues}
                    validationSchema = {validationSchema}
                    enableReinitialize
                    onSubmit= {async (values, { resetForm }) => {
                            const resp = await handleSubmit(values)
                            if (resp) {
                                resetForm()
                            }
                    }}     
                    >
                    {({values, errors, touched}) => {
                        
                        const setInpStyle = (prop, type, extra) => {
                            let inpStyle = `${extra} component__${type} `;
                            if (touched[prop] && errors[prop]) {
                                inpStyle += `component__${type}-err `
                            } else if (values[prop]) {
                                inpStyle += `component__${type}-success`
                            }
                            return inpStyle
                        }
                        return (
                            <Form action='post' className={`${styles.block} component__block`} >
                                <div className={`${styles.block__main} component__block-main`}>
                                    <div className={styles.form__block}>
                                        <p className="component__subt">Новое название</p>
                                        <div className={styles.block__wrapper}>
                                            <FormikControl
                                                control="input"
                                                type="text" 
                                                name="newName"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="component__block-submit">
                                    <button type="submit" className="component__btn component__btn-small">Сохранить</button>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>

            <div className={`component__union`}>
                <div className="component__union-type">
                    <h5 className="component__union-title">Закрыть счёт</h5>
                    <p className="component__union-descr">
                        Вы можете удалить счёт.
                    </p>
                </div>

                <div className={`${styles.block} component__block`}>
                    <div className={`${styles.block__main + " " + styles.submit__main} component__block-main`}>
                        <p className={styles.block__descr}>
                        Закрыть счёт навсегда
                        </p>
                    </div>
                    <div className={`component__block-submit ${styles.submit__block}`}>
                        <button 
                        type="button" 
                        className="component__btn component__btn-small component__btn-cancel"
                        >
                            Удалить
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SettingsParams