import { useState, useEffect } from 'react';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';

import ProjectForm  from './ProjectForm';
import styles from './Project.module.scss'

const EditProject = () => {
    const [project, setProject] = useOutletContext();
    const {id} = useParams();

    return (
        <>
            <ProjectForm id={id} project={project} setProject={setProject}/>
        </>
    )
}

export default EditProject;